import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  IGetScansQuery,
  IGetScansServerResponse,
  IMobileScanOperatingSystem,
  IScan,
} from '../../../shared/interfaces/scan.interface';
import { UserPropertyService } from './user-property.service';
import { ICurrentSelectedProperty } from './user.service';
import { RestService } from './rest.service';
import { IMobileScanRuleInfo } from '../interfaces/mobile-scan.interface';

@Injectable({
  providedIn: 'root',
})
export class ScanMobileService {
  constructor(
    private restService: RestService,
    private userPropertyService: UserPropertyService,
  ) {}

  public getSDKOperatingSystems(): Observable<IMobileScanOperatingSystem[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IMobileScanOperatingSystem[]> =>
            this.restService.getMobileSDKScansOperatingSystems(workspaceId, digitalPropertyId),
        ),
      );
  }

  public getEmulatorOperatingSystems(): Observable<IMobileScanOperatingSystem[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IMobileScanOperatingSystem[]> =>
            this.restService.getMobileEmulatorScansOperatingSystems(workspaceId, digitalPropertyId),
        ),
      );
  }

  public getMobileScansRules(workspaceId: string, digitalPropertyId: string): Observable<IMobileScanRuleInfo[]> {
    return this.restService.getMobileScansRules(workspaceId, digitalPropertyId);
  }

  public getMobileEmulatorScans(
    searchParams: IGetScansQuery,
    limit: number,
    skip: number,
    sortBy: string = null,
  ): Observable<IGetScansServerResponse> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IGetScansServerResponse> =>
            this.restService.getMobileEmulatorScans(workspaceId, digitalPropertyId, searchParams, limit, skip, sortBy),
        ),
      );
  }

  public getMobileSDKScans(
    searchParams: IGetScansQuery,
    limit: number,
    skip: number,
    sortBy: string = null,
  ): Observable<IGetScansServerResponse> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IGetScansServerResponse> =>
            this.restService.getMobileSDKScans(workspaceId, digitalPropertyId, searchParams, limit, skip, sortBy),
        ),
      );
  }

  public removeMobileEmulatorScan(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IScan> {
    return this.restService.removeMobileEmulatorScan(workspaceId, digitalPropertyId, scanId);
  }

  public removeMobileSDKScan(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IScan> {
    return this.restService.removeMobileSDKScan(workspaceId, digitalPropertyId, scanId);
  }
}
