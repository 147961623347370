<app-login-layout *ngIf="useLoginLayout$ | async; else signupLayout"></app-login-layout>

<ng-template #signupLayout>
  <app-signup-layout *ngIf="useSignupLayout$ | async; else appLayout"></app-signup-layout>
</ng-template>

<ng-template #appLayout>
  <app-layout [routerOutlet]="routerOutlet"></app-layout>
</ng-template>

<ng-template #routerOutlet>
  <router-outlet></router-outlet>
</ng-template>

<app-toast aria-atomic="true"></app-toast>

<app-tooltip-component [zIndex]="zIndexGlobalTooltip"></app-tooltip-component>
<ds-tooltip [zIndex]="zIndexGlobalTooltip"></ds-tooltip>

<app-svg-image-filters></app-svg-image-filters>
<app-svg-images></app-svg-images>
<app-svg-icons></app-svg-icons>
<app-eap-icons></app-eap-icons>

<app-feature-flag-initialized></app-feature-flag-initialized>
