<nav
  [class.compound-top-navbar]="!showMobileNavVersion"
  [class.compound-top-navbar-mobile]="showMobileNavVersion"
  [attr.aria-label]="'top_level_navbar_label' | translate"
>
  <div class="container g-5">
    <ng-container *ngTemplateOutlet="showMobileNavVersion ? mobileVersion : desktopVersion"></ng-container>
  </div>
</nav>

<ng-template #mobileNavModal let-modal>
  <app-mobile-top-navbar-menu-modal [modal]="modal"></app-mobile-top-navbar-menu-modal>
</ng-template>

<ng-template #topNavLogo>
  <ds-link
    [rtLink]="logoUrl$ | async"
    queryParamsHandling="preserve"
    [standalone]="true"
    
    class="top-nav-logo-link"
  >
    <img
      class="top-navbar-logo-img"
      src="/static/assets/images/level-access-logo-new.png"
      srcset="/static/assets/images/level-access-logo-new.png 1x, /static/assets/images/level-access-logo-new@2x.png 2x"
      alt="{{ 'level_access_home' | translate }}"
    />
  </ds-link>
</ng-template>

<ng-template #desktopVersion>
  <div class="compound-top-navbar-left">
    <ng-container *ngTemplateOutlet="topNavLogo"></ng-container>
    <top-navbar-launcher-dropdown></top-navbar-launcher-dropdown>
    @if (organizationNavigationItem$ | async; as organizationNavigationItem) {
      <ds-link
        *ngIf="organizationNavigationItem.isAvailable"
        [icon]="Icons.Building"
        [standalone]="true"
        [rtLink]="organizationNavigationItem.routerLink"
        [linkColor]="DsLinkColor.White"
        
        >{{ 'organization' | translate }}
      </ds-link>
    }
    <top-navbar-workspaces-dropdown></top-navbar-workspaces-dropdown>
  </div>

  <div class="compound-top-navbar-middle">
    {{ navTitle$ | async }}
  </div>

  <div class="compound-top-navbar-right">
    <ds-link
      *ngIf="academyNavigationItem$ | async as academyNavigationItem"
      [icon]="academyNavigationItem.iconName"
      [standalone]="true"
      [rtLink]="academyNavigationItem.routerLink"
      [linkColor]="DsLinkColor.White"
      
      >{{ 'academy' | translate }}
    </ds-link>
    <top-nav-notifications *ngIf="hasAccessToNotifications$ | async"></top-nav-notifications>

    <ng-container *ngIf="navSupportPortalUxEnabled$ | async; else oldHelp">
      <ng-container *ngIf="helpNavigationItem$ | async as helpNavigationItem">
        <ds-dropdown
          class="top-nav-help"
          ds-tooltip="{{ helpNavigationItem.label | translate }}"
          [tooltipPlacement]="DsTooltipPosition.bottom"
        >
          <ds-dropdown-toggle
            [icon]="helpNavigationItem.iconName"
            [variant]="DsButtonVariants.microAction"
            [ariaLabel]="helpNavigationItem.label | translate"
          >
          </ds-dropdown-toggle>
          <div ds-dropdown-menu>
            <ds-dropdown-item
              *ngFor="let helpMenuItem of helpNavigationItem.children"
              [icon]="helpMenuItem.iconName"
              [href]="helpMenuItem.externalLinkFn(externalLinkService)"
              [label]="helpMenuItem.label | translate"
              [value]="helpMenuItem.id"
            >
            </ds-dropdown-item>
          </div>
        </ds-dropdown>
      </ng-container>
    </ng-container>

    <ng-template #oldHelp>
      <ng-container *ngIf="oldHelpNavigationItem$ | async as oldHelpNavigationItem">
        <ds-dropdown
          class="top-nav-old-help"
          ds-tooltip="{{ oldHelpNavigationItem.label | translate }}"
          [tooltipPlacement]="DsTooltipPosition.bottom"
        >
          <ds-dropdown-toggle
            [icon]="oldHelpNavigationItem.iconName"
            [variant]="DsButtonVariants.microAction"
            [ariaLabel]="oldHelpNavigationItem.label | translate"
          >
          </ds-dropdown-toggle>
          <div ds-dropdown-menu>
            <ds-dropdown-item
              *ngFor="let helpMenuItem of oldHelpNavigationItem.children"
              [icon]="helpMenuItem.iconName"
              [href]="helpMenuItem.externalLinkFn(externalLinkService)"
              [label]="helpMenuItem.label | translate"
              [value]="helpMenuItem.id"
            >
            </ds-dropdown-item>
          </div>
        </ds-dropdown>
      </ng-container>
    </ng-template>

    <ng-container *featureFlag="FeatureFlagCollection.navSupportPortalUx">
      <ng-container *ngIf="serviceRequestNavigationItem$ | async as serviceRequestNavigationItem">
        <ds-dropdown
          class="top-nav-service"
          ds-tooltip="{{ serviceRequestNavigationItem.label | translate }}"
          [tooltipPlacement]="DsTooltipPosition.bottom"
        >
          <ds-dropdown-toggle
            [icon]="serviceRequestNavigationItem.iconName"
            [variant]="DsButtonVariants.microAction"
            [ariaLabel]="serviceRequestNavigationItem.label | translate"
          >
          </ds-dropdown-toggle>
          <div ds-dropdown-menu>
            <ds-dropdown-item
              *ngFor="let serviceRequestMenuItem of serviceRequestNavigationItem.children"
              [icon]="serviceRequestMenuItem.iconName"
              [href]="serviceRequestMenuItem.externalLinkFn(externalLinkService)"
              [label]="serviceRequestMenuItem.label | translate"
              [value]="serviceRequestMenuItem.id"
            >
            </ds-dropdown-item>
          </div>
        </ds-dropdown>
      </ng-container>
    </ng-container>

    <top-navbar-profile-dropdown></top-navbar-profile-dropdown>
  </div>
</ng-template>

<ng-template #mobileVersion>
  <div class="top-nav-logo">
    <ng-container *ngTemplateOutlet="topNavLogo"></ng-container>
  </div>

  <button
    ds-button
    [variant]="DsButtonVariants.microActionStacked"
    [microActionIcon]="Icons.Bars"
    [color]="DsButtonColor.White"
    (click)="openMobileMenu()"
    
  >
    {{ 'menu' | translate }}
  </button>
</ng-template>
