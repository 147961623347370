import { Target as HarnessServerTarget } from '@harnessio/ff-nodejs-server-sdk';

import { ISecurityGroup } from './security-group.interface';
import { IApplicationRole } from './application-role.interface';
import { IWorkspaceRole } from './workspace-role.interface';

export interface UserContextAttributes {
  userId?: string;
  displayName?: string;
  email?: string;
  tenantId?: string;
  tenantName?: string;
  tenantDomain?: string;
  packageId?: string;
  packageName?: string;
  groups?: ISecurityGroup[] | string;
  // @deprecated: Old security
  applicationRoles?: IApplicationRole[] | string;
  // @deprecated: Old security
  workspaceRoles?: IWorkspaceRole[] | string;
  service?: string;
}

/**
 * Targets controls which users see which variation of a feature flag.
 * A target can be a userID, tenantID, application or a system
 *
 * Note: Targets can be both configured using the Harness Target Management page or directly
 * into the application by adding a target and its attributes via SDK.
 *
 * If a target is added via SDK, the Harness platform should
 * discover and populate it in the Harness Target Management page automatically.
 */
export type FeatureFlagServerTarget = HarnessServerTarget & UserContextAttributes;

/** This FeatureFlag enum should contain the list of feature flag keys defined in Harness Platform
 * Please make sure the flag key created in the Harness platform matches with the keys defined here.
 **/
export enum FeatureFlagCollection {
  userFlows = 'user_flows',
  userFlowsGuided = 'user_flows_guided',
  userFlowsQ42023Revamp = 'user_flows_q4_2023_revamp',
  recordingAutomatedFlows = 'recording_automated_flows',
  workspaceMigration = 'workspace_migration',
  workspaceMigrationResync = 'workspace_migration_resync',
  scansListRedesign = 'scans_list_redesign',
  scansRevampInPopup = 'scans_revamp_in_pop_up',
  salesforceIdLookup = 'salesforce_id_lookup',
  requestValidationTesting = 'request_validation_testing',
  dpRiskScore = 'dp_risk_score',
  wsRiskScore = 'ws_risk_score',
  govDashWebsiteAppsNewSection = 'gov_dash_websiteapps_new_section',
  automatedFlowPasswordCSSField = 'af_password_field',
  continuumIntegration = 'continuum_integration',
  labeForEveryone = 'labe_for_everyone',
  createLegacyEA = 'Create_LegacyEA_Tenant_LapDev',
  evaluationStatus = 'Evaluation_Status',
  tenantRiskScore = 'tenant_risk_score',
  tenantDashV1 = 'tenant_dash_v1',
  permissionLogging = 'permission_logging',
  beManEvaluations = 'be_man_evaluations',
  aiChat = 'AI_chat',
  aiChatResponseScale = 'AI_chat_response_scale',
  filteringUsers = 'filtering_users',
  scanBanner = 'scan_banner',
  customRulesAndSeverity = 'Custom_Rules_and_Severity',
  customSeverities = 'custom_severities',
  nonWcagStandardsPlatform = 'non_wcag_standards_platform',
  newPackageMonitoringLimits = 'new_package_monitoring_limits',
  skipToAIChat = 'skip_to_AI_chat',
  auditOnlyPackage = 'audit_only_package',
  bulkDpWithCustomMonitoringSettings = 'bulk_dp_with_custom_monitoring_settings',
  bulkWsAndDpsCreationWithCustomMonitoringSettings = 'bulk_ws_and_dps_creation_with_custom_monitoring_settings',
  pdfRemediationTestCount = 'pdf_remediation_test_count',
  eap_22459 = 'EAP22459',
  issueTrackingRedirect = 'issue_tracking_redirect',
  scimFunctionality = 'SCIM_functionality',
  removedStepper = 'removed_stepper',
  riskChartFrequency = 'risk_chart_frequency',
  organizationPortfolio = 'organization_portfolio',
  monitoringHistoricalReport = 'monitoring_historical_report',
  pushManualRequestAudit = 'push_manual_request_audit',
  evaluationsSummarization = 'evaluations_summarization',
  requestManualEvaluation = 'request_manual_evaluation',
  beRecorder = 'be_recorder',
  dcaDownload = 'dca_download',
  lastActiveSession = 'last_active_session',
  manualEvaluationConformanceTab = 'manual_evaluation_comformance_tab',
  userway_integration = 'userway_integration',
  updateTaskSlideout = 'update_task_slideout',
  mobileAppScans = 'mobile_app_scans',
  mobileTestingEmulator = 'mobile_testing_emulator',
  mobileTestingUseSauceLabs = 'mobile_testing_use_saucelabs',
  navSupportPortalUx = 'nav_support_portal_ux',
  requestLiveConsultation = 'request_live_consultation',
  skilljarIntegration = 'skilljar_integration',
  prescanFlow = 'prescan_flow',
  scanWithRobotstxt = 'SCAN_With_RobotTXT',
  labeVT = 'labe_VT',
  scanningAgent = 'scanning_agent',
  addWaitTimeAutoScan = 'add_wait_time_auto_scan',
  commonFindings = 'common_findings',
  packages_2025 = 'twenty_five_packages',
  navigationRefresh2025 = 'navigation_refresh_2025',
  viewIssueTrackingConnectionStatus = 'View_Issue_Tracking_Connection_Status',
  manageIssueTrackingConnectionInProjects = 'Manage_Issue_Tracking_Connection_in_Projects',
  mobileTestingSupportRealDevices = 'mobile_testing_support_real_devices',
  artificialIntelligenceOptin = 'artificial_intelligence_optin',
  digitalAssetFindings = 'Digital_asset_findings',
  digitalPropertyTagging = 'digital_property_tagging',
}

export function revertFlag(flag: FeatureFlagCollection): string {
  return `!${flag}`;
}
