import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras, ActivatedRoute, UrlTree } from '@angular/router';
import { AuthGuard as ExternalAuthGuard, AuthService as ExternalAuthService } from '@auth0/auth0-angular';
import { Observable, of, combineLatest } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';

import { UserService } from '../../user.service';
import { redirectUrl } from '../../../shared/constants';
import { ErrorHandlerService } from '../../error-handler.service';
import { Api } from '../../../../../shared/constants/api';
import { AppConfigService } from '../../app-config.service';
import { AuthService } from '../../auth.service';

@Injectable()
export class AuthGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private config: AppConfigService,
    private externalAuthGuard: ExternalAuthGuard,
    private externalAuthService: ExternalAuthService,
    private authService: AuthService,
  ) {}

  private internalCanActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.userService.isAuthenticated()) {
      return of(true);
    }

    if (next.url[0]?.path === Api.login) {
      return of(true);
    }

    const routeParams: NavigationExtras = {
      relativeTo: this.activatedRoute,
      queryParams: {
        [redirectUrl.returnTo]: state.url,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    };

    this.userService.processUnauthorization();

    this.router
      .navigate([`/${Api.login}`], routeParams)
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

    return of(false);
  }

  private getCachedOrUpdatedAccessTokenSilently(): Observable<string> {
    return this.externalAuthService.getAccessTokenSilently({ cacheMode: 'on' }).pipe(
      switchMap((accessToken: string): Observable<[string, boolean]> => {
        if (accessToken) {
          // trigger a test request to validate whether the IDP token interceptor will attach a valid access token
          return combineLatest([of(accessToken), this.userService.validateExternalAccessToken()]);
        }
        return of([null, false]);
      }),
      switchMap(([accessToken, isValid]: [string, boolean]): Observable<string> => {
        if (accessToken && !isValid) {
          // if the cached token is not valid, try to salvage the current IDP session by requesting a fresh access token
          // and proceed without validating the new token, redirecting to the access restricted page in the worst case
          return this.externalAuthService.getAccessTokenSilently({ cacheMode: 'off' });
        }
        return of(accessToken);
      }),
      catchError((error: HttpErrorResponse): Observable<string> => {
        console.error('[validateExternalAccessToken] error', error);
        this.router.navigate([`/${Api.forbidden}`]);
        return of(null);
      }),
    );
  }

  private externalCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.externalAuthGuard.canActivate(route, state).pipe(
      mergeMap((canActivate: boolean): Observable<string> => {
        if (canActivate) {
          return this.getCachedOrUpdatedAccessTokenSilently();
        }
        return of(null);
      }),
      mergeMap((accessToken: string): Observable<boolean> => {
        if (accessToken) {
          // UserService#getAuthStatus need to read access token from local storage
          this.userService.setMfaRequired(false);
          this.authService.saveAccessTokenToStorage(accessToken);
          return of(true);
        }
        this.userService.processUnauthorization();
        return of(false);
      }),
    );
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.config.isUsingExternalIdp()) {
      return this.externalCanActivate(route, state);
    }

    return this.internalCanActivate(route, state);
  }
}
