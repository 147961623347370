<div class="row flex-column">
  <div class="col-12">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <label [for]="domId" class="d-flex">
          <ng-content select=".markdown-editor-header"></ng-content>
        </label>
      </div>
      <div class="col-auto">
        <button class="btn btn-link px-2 mb-1" type="button" (click)="toggleMode()">
          <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="MarkdownEditorMode.Preview">
              <span class="text-underline me-2">{{ 'label_editing_view' | translate }}</span>
              <svg class="icon">
                <use xlink:href="#code-angled"></use>
              </svg>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="text-underline me-2">{{ 'label_styled_preview' | translate }}</span>
              <svg class="icon">
                <use xlink:href="#eye"></use>
              </svg>
            </ng-container>
          </ng-container>
        </button>
      </div>
    </div>
  </div>
  <div class="col-12">
    <ng-container [ngSwitch]="mode">
      <ng-container *ngSwitchCase="MarkdownEditorMode.Preview">
        <div
          class="form-control editor"
          [style.minHeight]="config.minHeight"
          [id]="domId"
          [innerHTML]="render() | safeContent"
        ></div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <textarea
          class="form-control editor"
          [(ngModel)]="internalValue"
          (ngModelChange)="propagateChange()"
          [id]="domId"
          [rows]="config.rows ?? 10"
          [disabled]="disabled"
          [class.resizable]="config.resizable"
          [style.minHeight]="config.minHeight"
          [attr.required]="config.required ? true : null"
        ></textarea>
      </ng-container>
    </ng-container>
  </div>
</div>
