import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private isApplicationReady: BehaviorSubject<boolean>;
  private areTranslationsReady: BehaviorSubject<boolean>;

  public $isApplicationReady: Observable<boolean>;
  public $areTranslationsReady: Observable<boolean>;

  constructor() {
    this.isApplicationReady = new BehaviorSubject(false);
    this.$isApplicationReady = this.isApplicationReady.asObservable();

    this.areTranslationsReady = new BehaviorSubject(false);
    this.$areTranslationsReady = this.areTranslationsReady.asObservable();
  }

  public setApplicationReadyStatus(status: boolean): void {
    this.isApplicationReady.next(status);
  }

  public setAreTranslationsReady(status: boolean): void {
    this.areTranslationsReady.next(status);
  }

  public getAreTranslationsReady(): boolean {
    return this.areTranslationsReady.getValue();
  }
}
