export enum $ruleLibrary {
  _id = '_id',
  name = 'name',
  origin = 'origin',
  mediaType = 'mediaType',
  code = 'code',
}

export enum RuleLibraryOrigin {
  legacyLa = 'legacyLa',
  legacyEa = 'legacyEa',
  la = 'la',
}

export enum RuleLibraryMediaType {
  any = 'any',
  web = 'web',
  android = 'android',
  iOS = 'iOS',
  design = 'design',
  pdf = 'pdf',
  embeddedSoftware = 'embeddedSoftware',
  windowsSoftware = 'windowsSoftware',
  kiosks = 'kiosks',
  microsoftWord = 'microsoftWord',
  microsoftExcel = 'microsoftExcel',
  microsoftPowerpoint = 'microsoftPowerpoint',
  macosSoftware = 'macosSoftware',
  hardware = 'hardware',
}

export enum ruleLibraryCodeNames {
  web_ea = 'web_ea',
  android_ea = 'android_ea',
  ios_ea = 'ios_ea',
  custom = 'custom',
  web_la = 'web_la',
  ios_la = 'ios_la',
  android_la = 'android_la',
  pdf_la = 'pdf_la',
  embedded_software_la = 'embedded_software_la',
  hardware_la = 'hardware_la',
  windows_software_la = 'windows_software_la',
  kiosks_la = 'kiosks_la',
  microsoft_word = 'microsoft_word',
  microsoft_excel = 'microsoft_excel',
  microsoft_powerpoint = 'microsoft_powerpoint',
  macos_software = 'macos_software',
  organization_custom = 'organization_custom',
  pv_web = 'pv_web',
}

export const librariesWithTestData: readonly ruleLibraryCodeNames[] = [
  ruleLibraryCodeNames.web_ea,
  ruleLibraryCodeNames.web_la,
  ruleLibraryCodeNames.organization_custom,
];
