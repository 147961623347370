import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Observable, of, Subscription, combineLatest } from 'rxjs';
import { filter, map, skip, switchMap } from 'rxjs/operators';
import { Location, ViewportScroller } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Params, Router, Scroll } from '@angular/router';

import { BusMessageChannels, BusMessageService } from './services/bus-message.service';
import { StatusService } from './services/status.service';
import { ModalService } from './services/modal.service';
import { TranslateService } from './translate/translate.service';
import { CommonUtility } from './utility/common.utility';
import { UserService } from './services/user.service';
import { IUserServerResponse } from '../../shared/interfaces/user.interface';
import { NotificationService } from './services/notification.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { Api } from '../../shared/constants/api';
import { AmplitudeService } from './services/amplitude.service';
import { UserPropertyService } from './services/user-property.service';
import { FocusedWindowService } from './services/focused-window.service';
import { SharedCommonUtility } from '../../shared/utils/common.utility';
import { ServerVersionService } from './services/server-version.service';
import { ServerVersionChangeModalComponent } from './components/server-version-change/server-version-change-modal.component';
import { CurrentSessionDataService } from './services/current-session-data.service';
import { BusyIndicatorService } from './services/busy-indicator/busy-indicator.service';
import { TenantPackageService } from './services/tenant-package.service';
import { $navigationCustomState, NavigationCustomState } from './interfaces/navigation-custom-state.interface';
import { LinkedPropertyUtility } from '../../shared/utils/linked-property.utility';
import { zIndexOverlay, zIndexTooltip } from '../sass/_variables';
import { verifyConfirmationEmailNotificationId } from '../../shared/constants/notifications';
import { RouteHelper } from './helpers/route.helper';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  public useLoginLayout$: Observable<boolean>;
  public useSignupLayout$: Observable<boolean>;
  public zIndexGlobalTooltip: number = zIndexOverlay + zIndexTooltip;

  constructor(
    private statusService: StatusService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private userService: UserService,
    private userPropertyService: UserPropertyService,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private googleTagManagerService: GoogleTagManagerService,
    private currentSessionDataService: CurrentSessionDataService,
    private busMessageService: BusMessageService,
    private amplitudeService: AmplitudeService,
    private focusedWindowService: FocusedWindowService,
    private serverVersionService: ServerVersionService,
    private location: Location,
    private tenantPackageService: TenantPackageService,
    busyIndicatorService: BusyIndicatorService,
  ) {
    this.subscriptions = new Subscription();

    this.useLoginLayout$ = combineLatest([
      this.userService.isAuthenticated$,
      RouteHelper.isLoginPage$(this.router),
      RouteHelper.isTenantLoginRedirectPage$(this.router),
    ]).pipe(
      map(
        ([isAuthenticated, isLoginPage, isTenantLoginRedirectPage]: [boolean, boolean, boolean]): boolean =>
          (!isAuthenticated && isLoginPage) || (isAuthenticated && isTenantLoginRedirectPage),
      ),
    );

    this.useSignupLayout$ = combineLatest([this.userService.isAuthenticated$, RouteHelper.isSignupPage$(this.router)]).pipe(
      map(([isAuthenticated, isSignupPage]: [boolean, boolean]): boolean => !isAuthenticated && isSignupPage),
    );

    this.focusedWindowService.init();
    this.handleRouterScroller();
    this.subscriptions.add(busyIndicatorService.a11MessageOnIndicatorChange().subscribe());
  }

  private loadUserDataAfterAuthenticated(): void {
    this.subscriptions.add(
      combineLatest([this.userService.isAuthenticated$, this.userService.userDataChanged$])
        .pipe(
          switchMap(([isAuthenticated, userData]: [boolean, IUserServerResponse]): Observable<IUserServerResponse> => {
            if (isAuthenticated !== true) {
              return of(null);
            }

            if (SharedCommonUtility.notNullishOrEmpty(userData)) {
              return of(userData);
            }

            return this.userService.getMeProfile();
          }),
        )
        .subscribe(),
    );
  }

  private handleRouterScroller(): void {
    const onlyScrollEvent = (e: any): boolean => {
      return e instanceof Scroll && !(e.routerEvent instanceof NavigationSkipped);
    };

    const processScrollEvent = (e: Scroll): void => {
      const viewportScroller: ViewportScroller = this.viewportScroller;

      const handleScroll = (): void => {
        const cb = (): void => {
          if (e.position) {
            viewportScroller.scrollToPosition(e.position);
            return;
          }

          if (e.anchor) {
            if (e.routerEvent.url !== (e.routerEvent as NavigationEnd).urlAfterRedirects) {
              viewportScroller.scrollToAnchor(e.anchor);
              return;
            }
            return;
          }

          const navigationState: NavigationCustomState = this.location.getState();
          if (navigationState?.[$navigationCustomState.scrollToTop] !== false) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        };

        window.setTimeout(cb, 200);
      };

      window.requestAnimationFrame(handleScroll);
    };

    this.subscriptions.add(
      this.router.events.pipe(filter(onlyScrollEvent)).subscribe({
        next: processScrollEvent,
      }),
    );
  }

  private hidePageLoader(): void {
    const pageLoaderElement: HTMLElement = document.querySelector('.page-loader');
    const pageLoaderStyles: HTMLElement = document.getElementById('loaderStyles');
    const loadingScript: HTMLElement = document.getElementById('loadingScript');

    if (pageLoaderElement && CommonUtility.isHostMethod(pageLoaderElement, 'remove')) {
      pageLoaderElement.remove();
    }

    if (pageLoaderStyles && CommonUtility.isHostMethod(pageLoaderStyles, 'remove')) {
      pageLoaderStyles.remove();
    }

    if (loadingScript && CommonUtility.isHostMethod(loadingScript, 'remove')) {
      loadingScript.remove();
    }
  }

  private applyTranslationsOnUIside(): void {
    const htmlRoot: Element = CommonUtility.getRootElement();
    const availableLanguage: string = this.translateService.currentLang;

    const langValue: string[] = availableLanguage.split('-');

    // Note:  according to https://tools.ietf.org/html/rfc3066 there could also be a subtag so the final language identification
    //        can have a format lang-region-subregion. This language section could be reviewed in the future.
    if (langValue.length === 2) {
      htmlRoot.setAttribute('lang', `${langValue[0]}-${langValue[1].toUpperCase()}`);
    } else if (langValue.length === 1) {
      htmlRoot.setAttribute('lang', langValue[0]);
    }

    // Note: here all components should be re-rendered
  }

  private initialSettings(): void {
    const htmlRoot: Element = CommonUtility.getRootElement();

    htmlRoot.setAttribute('data-useragent', window.navigator.userAgent);
    htmlRoot.setAttribute('data-platform', window.navigator.platform);
  }

  private setApplicationIsReadyStatus(): void {
    this.statusService.setApplicationReadyStatus(true);
  }

  private verifyConfirmationEmail(): void {
    const success = (userData: IUserServerResponse): void => {
      if (userData === null || typeof userData.emailVerified === 'undefined' || userData.emailVerified) {
        return;
      }

      const words: string[] = [userData.email, `/${Api.settings}/${Api.profile}`];
      const message: string = this.translateService.instant('activation_message', words);

      this.notificationService.inlineMessage(message, verifyConfirmationEmailNotificationId);
    };

    const error = (err: any): void => {
      console.error(err);

      const message: string = this.translateService.instant('activation_message_error', err.error.message);

      this.notificationService.inlineMessage(message, verifyConfirmationEmailNotificationId);
    };

    this.subscriptions.add(this.userService.userDataChanged$.subscribe(success, error));
  }

  public onQueryParameterChange(queryParams: Params): void {
    const { digitalPropertyId, workspaceId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(queryParams);

    if (typeof workspaceId !== 'string' || typeof digitalPropertyId !== 'string') {
      return;
    }

    const switchPropertySubscription: Subscription = this.userPropertyService
      .switchUserToDigitalPropertyIfNeeded(workspaceId, digitalPropertyId)
      .subscribe({
        next: (switched: boolean) => {
          if (switched) {
            this.busMessageService.to(BusMessageChannels.digitalPropertyChange).next();
          }
        },
      });
    this.subscriptions.add(switchPropertySubscription);
  }

  public subscribeToServerVersionChange(): void {
    this.subscriptions.add(
      this.serverVersionService.versionChange.subscribe({
        next: () => this.modalService.open(ServerVersionChangeModalComponent),
      }),
    );
  }

  public subscribeToTenantPackageChange(): void {
    this.subscriptions.add(
      this.tenantPackageService.tenantPackageChange$.subscribe({
        next: () => this.modalService.open(ServerVersionChangeModalComponent),
      }),
    );
  }

  public ngOnInit(): void {
    this.hidePageLoader();
    this.initialSettings();
    this.loadUserDataAfterAuthenticated();
    this.subscribeToServerVersionChange();
    this.subscribeToTenantPackageChange();

    this.modalService.setRootViewContainerRef(this.viewContainerRef);

    const routerEventsSubscription: Subscription = this.activatedRoute.queryParams.subscribe({
      next: this.onQueryParameterChange.bind(this),
    });
    this.subscriptions.add(routerEventsSubscription);

    this.subscriptions.add(
      this.translateService.currentLanguage$.pipe(skip(1)).subscribe({ next: this.applyTranslationsOnUIside.bind(this) }),
    );

    this.subscriptions.add(
      this.busMessageService
        .from(BusMessageChannels.notificationsReady)
        .pipe(skip(1))
        .subscribe({
          next: this.verifyConfirmationEmail.bind(this),
        }),
    );

    this.setApplicationIsReadyStatus();
    this.googleTagManagerService.initialiseGoogleTagManager();
    this.currentSessionDataService.initialiseCurrentSessionData();
    this.amplitudeService.initialiseAmplitude();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.focusedWindowService.destroy();
  }
}
