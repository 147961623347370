import { IRequiredSecurity } from '../interfaces/security.interface';
import { FunctionalArea, SecureAction } from './security';
import { SecurityEntityLevel } from './security-group';
import { SharedCommonUtility } from '../utils/common.utility';

export class RequiredSecurities {
  public static readonly PDF_Testing_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly PDF_Testing_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.read],
    },
  });

  public static readonly PDF_Testing_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Testing_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Testing_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Testing_And_Remediation_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.read],
      [FunctionalArea.pdf_remediation]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Testing_And_Remediation_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_testing]: [SecureAction.read],
      [FunctionalArea.pdf_remediation]: [SecureAction.read],
    },
  });

  public static readonly PDF_Remediation_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_remediation]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Administration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_administration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PDF_Administration_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pdf_administration]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Tasks_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_tasks]: [SecureAction.read],
    },
  });

  public static readonly PM_Tasks_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_tasks]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Tasks_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_tasks]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Tasks_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_tasks]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_TaskComments_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_task_comments]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_TaskComments_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_task_comments]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_TaskComments_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_task_comments]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_TaskAdmin_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_task_admin]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly API_Keys_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.read],
    },
  });

  public static readonly API_Keys_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.create],
    },
  });

  public static readonly API_Keys_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.delete],
    },
  });

  public static readonly API_Keys_AMP_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly API_Keys_AMP_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly API_Keys_AMP_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_keys]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly API_General_APIs_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_general_apis]: [SecureAction.read],
    },
  });

  public static readonly API_General_APIs_Read_WS: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_general_apis]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly API_General_APIs_Read_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_general_apis]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Evaluation_Rules_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.evaluation_rules]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Evaluation_Rules_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.evaluation_rules]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly DE_Administration_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_administration]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly DE_Administration_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_administration]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly DE_Administration_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly DE_Administration_Update_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly DE_Administration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_administration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly DE_Results_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_results]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly GR_Dashboard_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_dashboard]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly GR_Organization_Dashboard_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_organization_dashboard]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly TI_Issue_Tracking_Basic_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_basic]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly TI_Issue_Tracking_Basic_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_basic]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly TI_Issue_Tracking_Administration_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_administration]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly TI_Issue_Tracking_Basic_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_basic]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly TI_Issue_Tracking_Configure_Additional_Fields_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep(
    {
      functionalActions: {
        [FunctionalArea.ti_issue_tracking_configuration_additional_fields]: [SecureAction.update],
      },
      entityLevel: SecurityEntityLevel.tenant,
    },
  );

  public static readonly TI_Issue_Tracking_Administration_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly TI_Issue_Tracking_Administration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_issue_tracking_administration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly TI_Tools_Extensions_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_tools_and_extensions]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly TE_Test_Automation_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.te_test_automation]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly TE_Tools_Extensions_Testing_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.te_tools_and_extensions_testing]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly TI_SCIM_Config_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_scim_config]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly TI_SCIM_Config_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ti_scim_config]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly SDK_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.sdk]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly SDK_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.sdk]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Master_Library_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.master_library]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Master_Library_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.master_library]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly Master_Library_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.master_library]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Master_Library_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.master_library]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Scan_Tags_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.scan_tags]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Scan_Tags_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.scan_tags]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Scan_Tags_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.scan_tags]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Scan_Tags_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.scan_tags]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Audits_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Manual_Audit_Evaluations_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.read],
      [FunctionalArea.mt_manual_audits]: [SecureAction.read],
    },
    // no entityLevel - this security is used to obtain test guides -> even if the privilege is DP level, we still need
    // to read the testguides which is a Tenant-level object
  });

  public static readonly MT_Manual_Audit_Read_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Audit_Update_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Audit_Delete_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Audits_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Manual_Audits_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Manual_Audits_Create_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.update, SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Manual_Audits_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Manual_Audits_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_audits]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly MT_Comments_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Comments_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Comments_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Comments_Admin_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments_admin]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Tenants_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenants]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Tenants_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenants]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Tenants_Create_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenants]: [SecureAction.create, SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Tenants_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenants]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Admin_Tenants_Read_Workspaces_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenants]: [SecureAction.read],
      [FunctionalArea.workspaces]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Pages_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pages]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pages]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pages]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_Create_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pages]: [SecureAction.create, SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_Read_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pages]: [SecureAction.read, SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Flows_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.automated_flows]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Flows_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.automated_flows]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Flows_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.automated_flows]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Flows_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.automated_flows]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly PM_Projects_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Projects_Tasks_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.read],
      [FunctionalArea.pm_tasks]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Projects_Tasks_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.read],
      [FunctionalArea.pm_tasks]: [SecureAction.read],
    },
  });

  public static readonly PM_Projects_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Projects_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly PM_Projects_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.pm_projects]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly Documents_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.documents]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly Documents_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.documents]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly Documents_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.documents]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly Documents_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.documents]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly AT_Run_Automated_Scans_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_run_automated_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Run_Automated_Scans_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_run_automated_scans]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Scans_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Scans_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_scans]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Scans_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_scans]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Scans_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_scans]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Scans_API_General_APIs_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_scans]: [SecureAction.read],
      [FunctionalArea.api_general_apis]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Scans_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Scans_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_scans]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Scans_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_scans]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Scans_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_scans]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Scans_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_scans]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Apps_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_apps]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Apps_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_apps]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Apps_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_apps]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Apps_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_apps]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Emulator_Scans_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_emulator_scans]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Emulator_Scans_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_emulator_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Emulator_Scans_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_emulator_scans]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Emulator_Scans_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_emulator_scans]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AT_Mobile_Emulator_Scans_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.at_mobile_emulator_scans]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Comment_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Comment_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Update_Manual_Audit_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.update],
      [FunctionalArea.mt_manual_audits]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Comment_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Comment_Admin_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_comments_admin]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly MT_Manual_Evaluation_Update_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.delete, SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Component_Matching_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.component_matching]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Component_Matching_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.component_matching]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Component_Matching_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.component_matching]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Component_Matching_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.component_matching]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly User_Groups_Read_Staff_Groups_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.user_groups]: [SecureAction.read],
      [FunctionalArea.staff_groups]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly User_Groups_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.user_groups]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly User_Groups_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.user_groups]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly User_Groups_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.user_groups]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly User_Groups_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.user_groups]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly Staff_Groups_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.staff_groups]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Staff_Groups_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.staff_groups]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Staff_Groups_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.staff_groups]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Staff_Groups_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.staff_groups]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Admin & Tenant
   *
   * Functional Area: Tenant Switcher
   *
   * Functional Action: Read
   */
  public static readonly Tenant_Switcher_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.tenant_switcher]: [SecureAction.read],
    },
  });

  public static readonly User_Flows_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_user_flows]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly User_Flows_API_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_user_flows]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly User_Flows_API_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_user_flows]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_pages]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Pages_API_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_pages]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Global_Users_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gu_global_users]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Global_Users_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gu_global_users]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Global_Users_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gu_global_users]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Global_Users_All_Tenant_Admin_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gu_all_tenant_admin]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Notifications_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.notifications]: [SecureAction.read],
    },
  });

  public static readonly UM_User_Administration_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly UM_User_Administration_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.read],
    },
  });

  public static readonly UM_User_Administration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly UM_User_Administration_Create_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.create],
    },
  });

  public static readonly UM_User_Administration_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly UM_User_Administration_Update_Tenant_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly UM_User_Administration_Create_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.create, SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  public static readonly UM_Organization_Administration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_organization_administration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: false,
  });

  public static readonly UM_Organization_Administration_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_organization_administration]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: false,
  });

  /**
   * Environment: Tenant
   *
   * Functional Area: User Administration
   *
   * Functional Action: Update
   *
   * Entity Agnostic
   */
  public static readonly UM_User_Administration_Update_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.update],
    },
  });

  /**
   * Environment: Tenant
   *
   * Functional Area: User Administration
   *
   * Functional Action: Create or Update
   *
   * Entity Agnostic
   */
  public static readonly UM_User_Administration_Create_Update_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep(
    {
      functionalActions: {
        [FunctionalArea.um_user_administration]: [SecureAction.create, SecureAction.update],
      },
    },
  );

  /**
   * Environment: Admin or Tenant
   *
   * Functional Area: User Administration
   *
   * Functional Action: Delete
   */
  public static readonly UM_User_Administration_Delete_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.um_user_administration]: [SecureAction.delete],
    },
  });

  public static readonly Manual_Evaluations_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.read],
    },
  });

  public static readonly Manual_Evaluations_API_Read_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Evaluations_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.read],
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Evaluations_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.mt_manual_evaluations]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Evaluations_API_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Evaluations_API_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Manual_Evaluations_API_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_manual_evaluations]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Scans_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_automated_scans]: [SecureAction.read],
    },
  });

  public static readonly Automated_Scans_API_Update_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_automated_scans]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Scans_API_Read_Tenant: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_automated_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Automated_Scans_API_Read_DP: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_automated_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Automated_Scans_API_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_automated_scans]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Tenant_Admin_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_tenant_admin]: [SecureAction.read],
    },
  });

  public static readonly Tenants_API_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_tenants]: [SecureAction.read],
    },
  });

  /**
   * Environment: Admin or Tenant
   *
   * Functional Area: Workspaces
   *
   * Functional Action: Create
   */
  public static readonly Workspaces_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
    allowAdminAccess: true,
  });

  /**
   * Environment: Admin and Tenant
   *
   * Functional Area: Workspaces
   *
   * Functional Action: Update
   */
  public static readonly Workspaces_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
    allowAdminAccess: true,
  });

  /**
   * Environment: Admin
   *
   * Functional Area: Workspaces
   *
   * Functional Action: Delete
   */
  public static readonly Workspaces_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Translations_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.translations]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly Translations_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.translations]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Translations
   *
   * Functional action: Delete
   */
  public static readonly Translations_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.translations]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Translations
   *
   * Functional action: Create
   */
  public static readonly Translations_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.translations]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Translations
   *
   * Functional action: Download
   */
  public static readonly Translations_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.translations]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin and Tenant
   *
   * Functional area: Digital Properties
   *
   * Functional action: Read
   */
  public static readonly Digital_Properties_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.digital_properties]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
    allowAdminAccess: true,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Workspaces or Digital Properties
   *
   * Functional action: Read
   */
  public static readonly Workspaces_Read_Digital_Properties_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.read],
      [FunctionalArea.digital_properties]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  /**
   * Environment: Admin and Tenant
   *
   * Functional area: Digital Properties
   *
   * Functional action: Create
   */
  public static readonly Digital_Properties_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.digital_properties]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
    allowAdminAccess: true,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Digital Properties
   *
   * Functional action: Update
   */
  public static readonly Digital_Properties_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.digital_properties]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.workspace,
    allowAdminAccess: true,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Digital Properties
   *
   * Functional action: Delete
   */
  public static readonly Digital_Properties_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.digital_properties]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  /**
   * Environment: Admin and Tenant
   *
   * Functional area: Workspace
   *
   * Functional action: Read
   */
  public static readonly Workspaces_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
    allowAdminAccess: true,
  });

  public static readonly Workspaces_Read_No_Entity_Level: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.read],
    },
  });

  public static readonly Workspaces_Read_Digital_Properties_Read_No_Entity_Level: IRequiredSecurity =
    SharedCommonUtility.freezeDeep({
      functionalActions: {
        [FunctionalArea.workspaces]: [SecureAction.read],
        [FunctionalArea.digital_properties]: [SecureAction.read],
      },
    });

  /**
   * Environment: Tenant
   *
   * Functional area: Workspace
   *
   * Functional action: Download
   */
  public static readonly Workspaces_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.workspaces]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Governance
   *
   * Functional action: Download
   */
  public static readonly GR_Governance_Download: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_governance]: [SecureAction.download],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });
  /**
   * Environment: Tenant
   *
   * Functional area: Monitoring
   *
   * Functional action: Create
   */
  public static readonly GR_Monitoring_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_monitoring]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });
  /**
   * Environment: Tenant
   *
   * Functional area: Monitoring
   *
   * Functional action: Read
   */
  public static readonly GR_Monitoring_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_monitoring]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Monitoring
   *
   * Functional action: Update
   */
  public static readonly GR_Monitoring_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_monitoring]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Monitoring
   *
   * Functional action: Create or Update
   */
  public static readonly GR_Monitoring_Create_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_monitoring]: [SecureAction.create, SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Monitoring
   *
   * Functional action: Delete
   */
  public static readonly GR_Monitoring_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_monitoring]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Governance
   *
   * Functional action: Read
   */
  public static readonly GR_Governance_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_governance]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Governance
   *
   * Functional action: Create
   */
  public static readonly GR_Governance_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.gr_governance]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  /**
   * Environment: Admin and Tenant
   *
   * Functional area: Academy
   *
   * Functional action: Read
   */
  public static readonly Academy_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.academy]: [SecureAction.read],
    },
  });

  /**
   * Environment: Admin
   *
   * Functional area: Academy
   *
   * Functional action: Create
   */
  public static readonly Admin_Academy_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.academy]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Academy
   *
   * Functional action: Read
   */
  public static readonly Admin_Academy_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.academy]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Academy
   *
   * Functional action: Update
   */
  public static readonly Admin_Academy_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.academy]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: User, Admin
   *
   * Functional area: Design Rule Categories
   *
   * Functional action: Read
   */
  public static readonly DesignRuleCategories_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.de_results]: [SecureAction.read],
      [FunctionalArea.master_library]: [SecureAction.read],
    },
  });

  /**
   * Environment: Admin
   *
   * Functional area: Active scans
   *
   * Functional action: Read
   */
  public static readonly Admin_Active_Scans_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.active_scans]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  /**
   * Environment: User
   *
   * Functional area: Service Support
   *
   * Functional action: Create
   */
  public static readonly Service_Support_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.service_support]: [SecureAction.create],
    },
  });

  /**
   * Environment: User
   *
   * Functional area: Validation Testing
   *
   * Functional action: Create
   */
  public static readonly Validation_Testing_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.validation_testing]: [SecureAction.create],
    },
  });

  /**
   * Used for development and testing.
   */
  public static readonly Invalid: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.invalid]: [SecureAction.read],
    },
  });

  public static readonly Webhook_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.webhook]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Webhook_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.webhook]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Webhook_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.webhook]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Webhook_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.webhook]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Digital property
   *
   * Functional area: Preproduction Testing Environment Settings
   *
   * Functional action: Create
   */
  public static readonly Environment_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.preprod_testing_env_settings]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Digital property
   *
   * Functional area: Preproduction Testing Environment Settings
   *
   * Functional action: Read
   */
  public static readonly Environment_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.preprod_testing_env_settings]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  /**
   * Environment: Admin
   *
   * Functional area: Summary Snapshot Api
   *
   * Functional action: Create
   */
  public static readonly Summary_Snapshot_Api_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.summary_snapshot_api]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.admin,
  });

  public static readonly AI_CHAT_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ai_chat]: [SecureAction.create],
    },
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Tenant Custom Rule Library Management
   *
   * Functional action: Create
   */
  public static readonly Tenant_Rule_Library_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.trl_custom_rule_library_management]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Tenant Custom Rule Library Management
   *
   * Functional action: Read
   */
  public static readonly Tenant_Rule_Library_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.trl_custom_rule_library_management]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Tenant Custom Rule Library Management
   *
   * Functional action: Update
   */
  public static readonly Tenant_Rule_Library_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.trl_custom_rule_library_management]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: Tenant Custom Rule Library Management
   *
   * Functional action: Delete
   */
  public static readonly Tenant_Rule_Library_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.trl_custom_rule_library_management]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: SCIM API
   *
   * Functional action: Create
   */
  public static readonly SCIM_API_CREATE: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_scim]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: SCIM API
   *
   * Functional action: Update
   */
  public static readonly SCIM_API_UPDATE: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_scim]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: SCIM API
   *
   * Functional action: Delete
   */
  public static readonly SCIM_API_DELETE: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_scim]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Tenant
   *
   * Functional area: SCIM API
   *
   * Functional action: Read
   */
  public static readonly SCIM_API_READ: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.api_scim]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  /**
   * Environment: Workspace
   *
   * Functional area: Organization Portfolio
   *
   * Functional action: Read
   */
  public static readonly Organization_Portfolio_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.organization_portfolio]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.workspace,
  });

  public static readonly Manual_Request_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.request_manual_evaluation]: [
        SecureAction.read,
        SecureAction.update,
        SecureAction.create,
        SecureAction.delete,
      ],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly UserWay_Integration_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.userway_integration]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly UserWay_Integration_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.userway_integration]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly UserWay_Integration_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.userway_integration]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly CustomSeverities_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.custom_severities]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly CustomSeverities_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.custom_severities]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly CustomSeverities_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.custom_severities]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly CustomSeverities_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.custom_severities]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly Common_Findings_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Tags_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings_tags]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Tags_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings_tags]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Tags_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings_tags]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly Common_Findings_Tags_Delete: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.cf_common_findings_tags]: [SecureAction.delete],
    },
    entityLevel: SecurityEntityLevel.digitalProperty,
  });

  public static readonly AI_Features_Read: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ai_features]: [SecureAction.read],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly AI_Features_Create: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ai_features]: [SecureAction.create],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });

  public static readonly AI_Features_Update: IRequiredSecurity = SharedCommonUtility.freezeDeep({
    functionalActions: {
      [FunctionalArea.ai_features]: [SecureAction.update],
    },
    entityLevel: SecurityEntityLevel.tenant,
  });
}
