import { Injectable } from '@angular/core';
import { openDB } from 'idb';
import { IDBPDatabase } from 'idb/build/entry';
import { catchError, map } from 'rxjs/operators';

import { from, Observable } from 'rxjs';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { LoadErrorHandler } from './load-error-handler';
import { TranslateService } from '../translate/translate.service';
import { IMobileScanWithScreenData } from '../../../shared/interfaces/mobile-app.interface';

export enum IndexedDBCollectionName {
  __storageTest__ = '__storageTest__',
  MOBILE_EMULATOR_SCANS = 'MOBILE_EMULATOR_SCANS',
}

interface CollectionTypeMapping {
  [IndexedDBCollectionName.__storageTest__]: any;
  [IndexedDBCollectionName.MOBILE_EMULATOR_SCANS]: IMobileScanWithScreenData[];
}

@Injectable()
export class IndexedDBService {
  private db: IDBPDatabase;

  constructor(
    private loadErrorHandler: LoadErrorHandler,
    private translateService: TranslateService,
  ) {
    this.initDb();
  }

  private initDb(): void {
    openDB('LEVELACCESS', 1, {
      upgrade: (db: IDBPDatabase): void => {
        Object.values(IndexedDBCollectionName).forEach((collectionName: IndexedDBCollectionName): void => {
          db.createObjectStore(collectionName);
        });
      },
    })
      .then((db: IDBPDatabase): void => {
        this.db = db;
      })
      .catch((): void => {
        console.error(`${IndexedDBService.name} did not initialize properly`);
      });
  }

  private throwUninitializedError(): never {
    this.loadErrorHandler.handleError('indexed_db_uninitialized_error', null, IndexedDBService.name);
    throw new Error(this.translateService.instant('indexed_db_uninitialized_error'));
  }

  private throwIndexedDBAccessError(err: Error): Observable<never> {
    console.error(`${IndexedDBService.name} db access error ${err}`);
    this.loadErrorHandler.handleError('indexed_db_access_error', null, IndexedDBService.name);
    throw new Error(this.translateService.instant('indexed_db_access_error'));
  }

  private get isInitialized(): boolean {
    return !SharedCommonUtility.isNullish(this.db);
  }

  public getCollection<T extends IndexedDBCollectionName>(
    collection: IndexedDBCollectionName,
  ): Observable<CollectionTypeMapping[T][]> | never {
    if (!this.isInitialized) {
      return this.throwUninitializedError();
    }

    return from(this.db.getAll(collection)).pipe(catchError(this.throwIndexedDBAccessError.bind(this)));
  }

  public setItem<T extends IndexedDBCollectionName>(
    collection: IndexedDBCollectionName,
    key: string,
    value: CollectionTypeMapping[T],
  ): Observable<void> | never {
    if (!this.isInitialized) {
      this.throwUninitializedError();
    }

    return from(this.db.put(collection, value, key)).pipe(
      map((): void => {}),
      catchError(this.throwIndexedDBAccessError.bind(this)),
    );
  }

  public clear(collection: IndexedDBCollectionName): Observable<void> | never {
    if (!this.isInitialized) {
      this.throwUninitializedError();
    }

    return from(this.db.clear(collection)).pipe(catchError(this.throwIndexedDBAccessError.bind(this)));
  }
}
