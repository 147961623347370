import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subscription, Observable } from 'rxjs';
import { identity } from 'lodash';

import { Icons } from '@levelaccess/design-system';
import { CommonUtility } from '../../utility/common.utility';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { TranslateService } from '../../translate/translate.service';

/**
 * typeahead multiselect component. can be used as a form component. operates on actual object values - can be any
 * complex object or just strings. supports transforming the actual value objects:
 *   1) before rendering them in the dropdown list. @see #valueToDropdownLabelFunction
 *   2) before rendering them in the selected items list. useful when you for example only want to display an
 *     id of the selected item but show its full description in the dropdown. @see #valueToSelectedItemLabelFunction
 *   3) before emitting selected values. @see #valueToOutputValueFunction and @see #registerOnChange from the
 *     ControlValueAccessor interface.
 * apart form that, also allows to get values dynamically based on input @see fetchPossibleValues
 */
@Component({
  selector: 'app-multiselect-typeahead',
  templateUrl: './multiselect-typeahead.component.html',
  styleUrls: ['./multiselect-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectTypeaheadComponent),
    },
  ],
})
export class MultiselectTypeaheadComponent<T, U = T> implements OnInit, OnDestroy, ControlValueAccessor {
  private subscription: Subscription;
  private readonly uniqueId: string;
  private onTouched: () => any;
  private onChange: (values: any) => any;

  @Input() public label: string;
  @Input() public noLabel: boolean;
  @Input() public selectedLabel: string;
  @Input() public formControlName: string;
  @Input() public disabled: boolean;
  @Input() public possibleValues: T[];
  @Input() public allowCustomValues: boolean;
  @Input() public id: string;
  /**
   * used if you need to display in the dropdown list of available options something else than the actual available
   * values.
   * if present it's called before rendering the selection dropdown with available options
   */
  @Input() public valueToDropdownLabelFunction: (item: T) => string;
  /**
   * if present it's called before rendering selected items to screen
   */
  @Input() public valueToSelectedItemLabelFunction: (item: T) => string;
  /**
   * if present it's called when adding custom values to the selection
   */
  @Input() public dropdownLabelToValueFunction: (item: string) => T;

  /**
   * if present, it's called in getter "values" transforming them from T[] to U[]. this affects what's
   * emitted through onChange and valueChange.
   */
  @Input() public valueToOutputValueFunction: (item: T) => U;

  @Input() public outputValueToValueFunction: (item: U) => T;

  @Input() public fetchPossibleValues: (term: string) => Observable<T[]>;

  @Input() public required: boolean = false;
  @Input() public formValidationRequest$: Observable<void>;
  @Input() public form: UntypedFormGroup;
  @Input() public maxValues?: number;
  @Input() public maxValueLength?: number;
  @Input() public description?: string = '';
  @Input() public isAlphanumericValidationEnabled?: boolean = false;

  @ViewChild('inputElement') public inputElement: ElementRef<HTMLInputElement>;

  public search: (text$: Observable<string>) => Observable<string[]>;
  public selectedValues: T[];
  public formId: string;
  public innerForm: UntypedFormGroup;
  public get domID(): string {
    return this.id || this.uniqueId;
  }
  public errorMessage: string = '';
  public readonly Icons: typeof Icons;

  public constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.label = '';
    this.noLabel = false;
    this.selectedLabel = '';
    this.disabled = false;
    this.possibleValues = [];
    this.allowCustomValues = false;
    this.search = this.searchValue.bind(this);
    this.selectedValues = [];
    this.formId = this.createDomID('multiselectForm');
    this.subscription = new Subscription();
    this.valueToOutputValueFunction = identity;
    this.valueToDropdownLabelFunction = identity;
    this.dropdownLabelToValueFunction = identity;
    this.outputValueToValueFunction = identity;
    this.valueToSelectedItemLabelFunction = (selectedItem: T): string => selectedItem?.toString();
    this.uniqueId = CommonUtility.createUniqueDOMId();
    this.Icons = Icons;
  }

  private searchValue(text$: Observable<string>): Observable<string[]> {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        let result: Observable<T[]>;
        if (term.length < 2) {
          result = of([]);
        } else if (!SharedCommonUtility.isNullish(this.fetchPossibleValues)) {
          result = this.fetchPossibleValues(term);
        } else {
          result = of(this.possibleValues);
        }

        return result.pipe(
          map((possibleValues: T[]) => {
            return Array.from(possibleValues) // sort mutates the array, so we clone it before sorting
              .filter((v: T) => this.valueToDropdownLabelFunction(v).toLowerCase().indexOf(term.toLowerCase()) > -1)
              .sort((a: T, b: T) => {
                const lowerTerm: string = term.toLowerCase();
                const lowerA: string = this.valueToDropdownLabelFunction(a).toLowerCase();
                const lowerB: string = this.valueToDropdownLabelFunction(b).toLowerCase();

                // Strings starting with the term come first
                const aStartsWith: boolean = lowerA.startsWith(lowerTerm);
                const bStartsWith: boolean = lowerB.startsWith(lowerTerm);
                if (aStartsWith && !bStartsWith) {
                  return -1;
                }
                if (bStartsWith && !aStartsWith) {
                  return 1;
                }

                // Fall back to alphabetical order, sorting numerically and case-insensitively
                return lowerA.localeCompare(lowerB, undefined, { numeric: true, sensitivity: 'base' });
              })
              .slice(0, 10)
              .map((v: T) => this.valueToDropdownLabelFunction(v));
          }),
        );
      }),
      tap(() => {
        if (!SharedCommonUtility.isNullish(this.onTouched)) {
          this.onTouched();
        }
      }),
      finalize(() => this.changeDetectorRef.detectChanges()),
    );
  }

  private emitValues(): void {
    if (!SharedCommonUtility.isNullish(this.onChange)) {
      this.onChange(this.values);
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * @label
   * @returns the value for which this.valueToOption(..) returns [label] or null if no such option exists.
   */
  private findSelectedValueByLabel(label: any, labelFunction: (T) => any): T {
    return (
      this.possibleValues.find((value: T) => labelFunction(value).startsWith(label)) ??
      this.possibleValues.find((value: T) => labelFunction(value).includes(label))
    );
  }

  private addSelectedItem(inputText: string): void {
    if (!this.allowCustomValues) {
      this.addExistingValue(inputText);
      return;
    }

    const trimmedInput: string = inputText?.trim();
    if (!trimmedInput) {
      return;
    }

    this.processMultipleOrSingleValue(trimmedInput);
    this.resetInputField();
  }

  private addExistingValue(inputText: string): void {
    const matchingOption: T = this.findSelectedValueByLabel(inputText, this.valueToDropdownLabelFunction);

    if (!SharedCommonUtility.isNullish(matchingOption) && !this.selectedValues.includes(matchingOption)) {
      this.selectedValues.push(matchingOption);
      this.resetInputField();
      this.emitValues();
    }
  }

  private processMultipleOrSingleValue(trimmedInput: string): void {
    if (trimmedInput.includes(' ')) {
      this.processMultipleValues(trimmedInput);
    } else {
      this.processAndAddSingleTag(trimmedInput);
    }
  }

  private processMultipleValues(input: string): void {
    const inputParts: string[] = input
      .split(' ')
      .map((part: string) => part.trim())
      .filter((part: string) => part);

    inputParts.forEach((part: string) => {
      this.processAndAddSingleTag(part);
    });
  }

  private resetInputField(): void {
    const inputField: AbstractControl = this.innerForm.get(this.innerInputName);
    setTimeout(() => inputField.setValue(null), 0);
    this.changeDetectorRef.detectChanges();
  }

  private processAndAddSingleTag(inputText: string): void {
    if (!this.validateOnEnter(inputText)) {
      return;
    }

    let matchingOption: T = this.findSelectedValueByLabel(inputText, this.valueToDropdownLabelFunction);

    if (SharedCommonUtility.isNullish(matchingOption) && this.allowCustomValues) {
      matchingOption = this.dropdownLabelToValueFunction(inputText);
    }

    if (!SharedCommonUtility.isNullish(matchingOption) && this.selectedValues.includes(matchingOption) === false) {
      this.selectedValues.push(matchingOption);
      this.errorMessage = '';
      this.changeDetectorRef.detectChanges();
      this.emitValues();
    }
  }

  public get innerInputName(): string {
    return `${this.formControlName}_input`;
  }

  @Input() public set values(inValues: U[]) {
    if (SharedCommonUtility.isNullish(inValues)) {
      this.selectedValues = [];
      return;
    }

    inValues.forEach((inValue: U) => {
      let selectedItem: T = this.findSelectedValueByLabel(inValue, this.valueToOutputValueFunction);

      if (SharedCommonUtility.isNullish(selectedItem) && this.allowCustomValues) {
        selectedItem = this.outputValueToValueFunction(inValue);
      }

      this.selectedValues.push(selectedItem);
    });

    this.changeDetectorRef.detectChanges();
  }

  public get values(): U[] {
    return Array.from(this.selectedValues).map(this.valueToOutputValueFunction);
  }

  public removeValue(_value: T): void {
    const index: number = this.selectedValues
      .map(this.valueToSelectedItemLabelFunction)
      .indexOf(this.valueToSelectedItemLabelFunction(_value));
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.emitValues();
      this.inputElement?.nativeElement?.focus();
    }
  }

  public pushOnItemSelected(item: { item: any }): void {
    if (this.allowCustomValues) {
      if (SharedCommonUtility.notNullish(item) && SharedCommonUtility.notNullish(item.item)) {
        this.addSelectedItem(item.item);
      }
    } else {
      this.addSelectedItem(item.item);
    }
  }

  public pushOnEnter(): void {
    const inputText: string = this.innerForm.controls[this.innerInputName].value;

    if (this.allowCustomValues) {
      if (inputText && inputText.trim()) {
        this.addSelectedItem(inputText);
      }
    } else {
      this.addSelectedItem(inputText);
    }
  }

  public createDomID(prefix: string): string {
    return `${prefix}_${CommonUtility.createUniqueDOMId()}`;
  }

  /* ControlValueAccessor implementation */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: U[]): void {
    this.values = obj;
  }
  /* end of ControlValueAccessor implementation */

  public clear(): void {
    this.values = [];
    this.selectedValues = [];
    this.innerForm.get(this.innerInputName).setValue('');
    this.emitValues();
  }

  public ngOnInit(): void {
    this.innerForm = this.formBuilder.group({
      [this.innerInputName]: this.formBuilder.control(null),
    });

    this.subscription.add(
      this.innerForm.get(this.innerInputName).valueChanges.subscribe((value: string) => {
        if (value) {
          this.validateInput(value);
        }
      }),
    );

    if (this.formValidationRequest$) {
      this.subscription = this.formValidationRequest$.subscribe(() => {
        const field: AbstractControl = this.form.get(this.formControlName);
        field.markAsDirty();
        field.markAsTouched();
        const innerField: AbstractControl = this.innerForm.get(this.innerInputName);
        innerField.markAsDirty();
        innerField.markAsTouched();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  private updateErrorState(errorKey: string, params?: any[]): void {
    if (errorKey) {
      this.errorMessage = this.translateService.instant(errorKey, params);
    } else {
      this.errorMessage = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  private validateInput(inputText: string): boolean {
    if (!this.allowCustomValues) {
      return true;
    }

    if (this.isAlphanumericValidationEnabled && !/^[a-zA-Z0-9 ]+$/.test(inputText)) {
      this.updateErrorState('alpha_numeric_error');
      return false;
    }

    if (this.maxValueLength && inputText.length > this.maxValueLength) {
      this.updateErrorState('max_tag_char_length', [this.maxValueLength]);
      return false;
    }

    this.updateErrorState('');
    return true;
  }

  private isDuplicate(inputText: string): boolean {
    const normalizedInput = inputText.toLowerCase();
    const isDuplicate = this.selectedValues.some(
      (value: T) => this.valueToSelectedItemLabelFunction(value).toLowerCase() === normalizedInput,
    );

    if (isDuplicate) {
      this.updateErrorState('duplicate_tag_value');
      return true;
    }
    return false;
  }

  private exceedsMaxValues(): boolean {
    if (this.maxValues && this.selectedValues.length >= this.maxValues) {
      this.updateErrorState('max_tag_length', [this.maxValues]);
      return true;
    }
    return false;
  }

  public validateOnEnter(inputText: string): boolean {
    if (!this.allowCustomValues) {
      return true;
    }

    if (!inputText || inputText.trim() === '') {
      return false;
    }

    this.errorMessage = '';

    if (!this.validateInput(inputText)) {
      return false;
    }

    if (this.isDuplicate(inputText)) {
      return false;
    }

    if (this.exceedsMaxValues()) {
      return false;
    }

    return true;
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
