import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { IAuditRuleWithLibraryName, ICustomAuditRuleWithLibraryName } from '../audit-rule-view/audit-rule-view.component';
import { IAuditStandard } from '../../../../../shared/interfaces/audit-rule.interface';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../../services/success-criteria-formatter.service';
import { AuditStandards } from '../../../../../shared/constants/audit-standard';
import { $successCriteria } from '../../../../../shared/audits/definitions/success-criteria/constants';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../../../shared/interfaces/feature-flag.interface';
import { ICustomSeveritiesMap } from '../../../../../shared/interfaces/tenant.interface';
import { TenantSeveritiesService } from '../../../services/tenant-severities.service';
import { $severity } from '../../../../../shared/constants/accessibility';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { TranslateService } from '../../../translate/translate.service';
import { SharedTextUtility } from '../../../../../shared/utils/text.utility';
import { $customSeverity } from '../../../../../shared/constants/tenant';

@Component({
  selector: 'app-audit-delete-rule-modal-content',
  templateUrl: './audit-delete-rule-modal-content.component.html',
})
export class AuditDeleteRuleModalContentComponent implements OnInit, OnDestroy {
  @Input() public rule: ICustomAuditRuleWithLibraryName | IAuditRuleWithLibraryName;
  @Input() public isCustomRule: boolean;
  public customSeverityFF: boolean;
  public customSeverities: ICustomSeveritiesMap;
  public customSeverityKeysSortedByRank: $severity[];
  private subscriptions: Subscription;

  constructor(
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
    private featureFlagService: FeatureFlagService,
    private tenantSeveritiesService: TenantSeveritiesService,
    private translateService: TranslateService,
  ) {
    this.subscriptions = new Subscription();
  }

  public displayWCAGOrNACriteria(standard: IAuditStandard): string {
    return this.successCriteriaFormatterService.toDisplayCriterias({
      identifiers: [
        standard?.[AuditStandards.wcag]?.[$successCriteria.num],
        standard?.[AuditStandards.notApplicable]?.[$successCriteria.num],
      ],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaIdentifierWithLevel,
    });
  }

  public getSeverityLabel(severity: string): string {
    const customLabel: string = SharedTextUtility.capitalize(
      this.customSeverities?.get(severity as $severity)?.[$customSeverity.label],
    );
    if (this.customSeverityFF && SharedCommonUtility.notNullishOrEmpty(customLabel)) {
      return customLabel;
    }
    return this.translateService.instant(severity);
  }

  public ngOnInit(): void {
    if (this.isCustomRule) {
      this.subscriptions.add(
        combineLatest([
          this.tenantSeveritiesService.getAll(),
          this.featureFlagService.variation$(FeatureFlagCollection.customSeverities, false),
        ]).subscribe(([customSeverities, customSeverityFF]: [ICustomSeveritiesMap, boolean]) => {
          this.customSeverityFF = customSeverityFF;
          this.customSeverities = customSeverities;
          this.customSeverityKeysSortedByRank = SharedCommonUtility.notNullishOrEmpty(this.customSeverities)
            ? this.tenantSeveritiesService.getCustomSeverityKeysSortedByRank(this.customSeverities)
            : [];
        }),
      );
    } else {
      this.subscriptions.add(
        this.featureFlagService
          .variation$(FeatureFlagCollection.customSeverities, false)
          .subscribe((customSeverityFF: boolean) => {
            this.customSeverityFF = customSeverityFF;
          }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
