import { BasePaginatedTableController } from '../../shared/base-paginated-table-controller';
import { TranslateService } from '../../translate/translate.service';
import { IDclConfig, ITableConfig, ITableRow } from '../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../table/ngb-table/utilities/ngb-table.utilities';
import { IAuditIssueViewData } from '../../../../shared/interfaces/audit-issues-view.interface';
import { $auditIssuesView } from '../../../../shared/constants/audit-issues-view';
import { $sortingOrder } from '../../../../shared/constants/sort';
import { severityToComparable } from '../../../../shared/constants/accessibility';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../services/success-criteria-formatter.service';
import { ICustomSeveritiesMap } from '../../../../shared/interfaces/tenant.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { IDSSeverityCellConfig } from '../table/ngb-table/cells/ds-severity-cell/ds-severity-cell.component';
import { ISeverityCellConfig } from '../table/ngb-table/cells/severity-cell/severity-cell.component';
import { IParsedStandard } from '../../../../shared/interfaces/standard-parsing.interface';

enum tableColumns {
  ruleDescription = 'ruleDescription',
  findings = 'findings',
  successCriteriaConformanceLevel = 'successCriteriaConformanceLevel',
  severity = 'severity',
  open = 'open',
  dismissed = 'dismissed',
}

export class SupplementaryTableController extends BasePaginatedTableController<IAuditIssueViewData> {
  private ruleDescriptionAsLink: boolean;
  private displayAdditionalColumns: boolean;
  private customSeverities: ICustomSeveritiesMap;
  private showWcagTerminologyOnly: boolean;
  private includeStandard: IParsedStandard;

  constructor(
    translateService: TranslateService,
    availableEntriesPerPage: number[],
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
  ) {
    super(translateService, availableEntriesPerPage);
    this.entriesPerPage = availableEntriesPerPage[1];
  }

  private getTableConfig(): ITableConfig {
    return {
      caption: this.translateService.instant('best_practices'),
      columns: {
        [tableColumns.ruleDescription]: {
          translationKey: 'table_header_rule_description',
          sortingEnabled: true,
        },
        ...(this.displayAdditionalColumns
          ? {
              [tableColumns.successCriteriaConformanceLevel]: {
                translationKey: this.showWcagTerminologyOnly ? 'standard_and_criterion' : 'table_header_sc_conformance_level',
                sortingEnabled: true,
              },
              [tableColumns.severity]: {
                translationKey: 'table_column_severity',
                sortingEnabled: true,
                styles: {
                  width: '10%',
                },
              },
              [tableColumns.open]: {
                translationKey: 'open',
                sortingEnabled: true,
              },
              [tableColumns.dismissed]: {
                translationKey: 'dismissed',
                sortingEnabled: true,
              },
            }
          : {
              [tableColumns.findings]: {
                translationKey: 'findings',
                sortingEnabled: true,
              },
            }),
      },
    };
  }

  private getSuccessCriteriaInfo(identifiers: string[]): string {
    if (this.showWcagTerminologyOnly === true) {
      return this.successCriteriaFormatterService.toDisplayCriterias({
        identifiers: identifiers ?? [],
        separator: Separator.newLine,
        preset: CriteriaPreset.criteriaWithStandardFull,
        includeStandard: this.includeStandard,
      });
    }

    return this.successCriteriaFormatterService.toDisplayCriterias({
      identifiers: identifiers ?? [],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaFull,
    });
  }

  protected initialSort(): void {
    const sortFunction = (a: IAuditIssueViewData, b: IAuditIssueViewData): number =>
      this.sortFunc($sortingOrder.asc, a[$auditIssuesView.failures], b[$auditIssuesView.failures]);

    this.rawData.sort(sortFunction);
  }

  protected filterInitialData(rawData: IAuditIssueViewData[]): IAuditIssueViewData[] {
    return rawData.filter((val: IAuditIssueViewData) => val[$auditIssuesView.failures] > 0 || val[$auditIssuesView.ignores] > 0);
  }

  protected toTableData(rawData: IAuditIssueViewData[]): ITableRow[] {
    const toTableRow = (entry: IAuditIssueViewData): ITableRow => {
      const severityCell: IDclConfig<ISeverityCellConfig | IDSSeverityCellConfig> = SharedCommonUtility.notNullish(
        this.customSeverities,
      )
        ? NgbTableUtilities.DSSeverityCell({
            severity: entry[$auditIssuesView.severity],
            customSeverity: this.customSeverities.get(entry[$auditIssuesView.severity]),
          })
        : NgbTableUtilities.severityCell({
            severity: entry[$auditIssuesView.severity],
          });

      return {
        data: {
          [tableColumns.ruleDescription]: this.ruleDescriptionAsLink
            ? NgbTableUtilities.linkCell({
                text: entry[$auditIssuesView.ruleDescription],
                attributes: {
                  routerLink: entry[$auditIssuesView.link],
                  queryParams: entry[$auditIssuesView.queryParams],
                  queryParamsHandling: 'merge',
                },
              })
            : NgbTableUtilities.textCell({ text: entry[$auditIssuesView.ruleDescription] }),
          ...(this.displayAdditionalColumns
            ? {
                [tableColumns.successCriteriaConformanceLevel]: NgbTableUtilities.textCell({
                  text: this.getSuccessCriteriaInfo(entry[$auditIssuesView.successCriteriaIdentifier]),
                  classes: ['pre-line'],
                }),
                [tableColumns.severity]: severityCell,
                [tableColumns.open]: NgbTableUtilities.textCell({
                  text: String(entry[$auditIssuesView.failures]),
                }),
                [tableColumns.dismissed]: NgbTableUtilities.textCell({
                  text: String(entry[$auditIssuesView.ignores]),
                }),
              }
            : {
                [tableColumns.findings]: NgbTableUtilities.textCell({
                  text: String(entry[$auditIssuesView.failures]),
                }),
              }),
        },
      };
    };

    return rawData.map(toTableRow);
  }

  protected sortData(column: string, direction: $sortingOrder, a: IAuditIssueViewData, b: IAuditIssueViewData): number {
    let aValue: string | number = '';
    let bValue: string | number = '';

    switch (column) {
      case tableColumns.ruleDescription:
        aValue = a[$auditIssuesView.ruleDescription];
        bValue = b[$auditIssuesView.ruleDescription];
        break;
      case tableColumns.findings:
        aValue = a[$auditIssuesView.failures];
        bValue = b[$auditIssuesView.failures];
        break;
      case tableColumns.successCriteriaConformanceLevel:
        aValue = this.getSuccessCriteriaInfo(a[$auditIssuesView.successCriteriaIdentifier]);
        bValue = this.getSuccessCriteriaInfo(b[$auditIssuesView.successCriteriaIdentifier]);
        break;
      case tableColumns.severity:
        aValue = severityToComparable[a[$auditIssuesView.severity]];
        bValue = severityToComparable[b[$auditIssuesView.severity]];
        break;
      case tableColumns.open:
        aValue = String(a[$auditIssuesView.failures]);
        bValue = String(b[$auditIssuesView.failures]);
        break;
      case tableColumns.dismissed:
        aValue = String(a[$auditIssuesView.ignores]);
        bValue = String(b[$auditIssuesView.ignores]);
        break;
      default:
        // no default
        break;
    }

    return this.sortFunc(direction, aValue, bValue);
  }

  public setIssueData(
    rawData: IAuditIssueViewData[],
    { ruleDescriptionAsLink, displayAdditionalColumns }: { ruleDescriptionAsLink: boolean; displayAdditionalColumns: boolean },
    showWcagTerminologyOnly: boolean = false,
    includeStandard: IParsedStandard = null,
    customSeverities?: ICustomSeveritiesMap,
  ): void {
    this.ruleDescriptionAsLink = ruleDescriptionAsLink;
    this.customSeverities = customSeverities;
    this.showWcagTerminologyOnly = showWcagTerminologyOnly;
    this.includeStandard = includeStandard;
    // display success criteria & conformance level, severity, open and dismissed data
    this.displayAdditionalColumns = displayAdditionalColumns;
    this.tableConfig = this.getTableConfig();
    this.setInitialData(rawData);
  }
}
