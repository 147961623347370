import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delayWhen, distinctUntilChanged, filter, finalize, map, mergeMap, skip, take, tap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { groupBy, isEqual } from 'lodash';
import { ICurrentSelectedProperty, ICurrentSelectedWorkspace, UserService } from './user.service';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import {
  IDigitalPropertiesListResponse,
  IDigitalPropertyListItem,
  IDigitalPropertyWorkspace,
  IDpWorkspaceTenant,
} from '../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { $user } from '../../../shared/constants/user';
import { BusMessageChannels, BusMessageService } from './bus-message.service';
import { IWorkspaceClient } from '../../../shared/interfaces/workspace.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { UserRestAPI } from './rest/user.api';
import { IWorkspacePropsType } from '../interfaces/workspace.interface';
import { $workspace } from '../../../shared/constants/workspace';
import { $tenant } from '../../../shared/constants/tenant';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../shared/utils/linked-property.utility';
import { Api } from '../../../shared/constants/api';

@Injectable({ providedIn: 'root' })
export class UserPropertyService {
  private currentlySwitchingProperties: BehaviorSubject<Set<string>>;
  private readonly hasSwitchingProperties: Observable<boolean>;

  constructor(
    private userService: UserService,
    private userRestAPI: UserRestAPI,
    private activatedRoute: ActivatedRoute,
    private busMessageService: BusMessageService,
    private router: Router,
  ) {
    this.currentlySwitchingProperties = new BehaviorSubject<Set<string>>(new Set<string>());
    this.hasSwitchingProperties = this.currentlySwitchingProperties.asObservable().pipe(
      filter((props: Set<string>): boolean => props.size === 0),
      map(() => true),
    );
  }

  private getCurrentPropertyFromParams(): Observable<ICurrentSelectedProperty> {
    return this.userService.userDataChanged$.pipe(
      delayWhen((): Observable<boolean> => this.hasSwitchingProperties),
      take(1),
      mergeMap(() => this.userService.currentDigitalProperty$),
      take(1),
      map(UserPropertyService.toCurrentSelectedProperty),
    );
  }

  private static toCurrentSelectedProperty(item: IDigitalPropertyListItem): ICurrentSelectedProperty | null {
    if (typeof item === 'undefined' || item === null) {
      return null;
    }

    return {
      digitalPropertyId: item[$digitalProperty._id],
      digitalPropertyName: item[$digitalProperty.name],
      digitalPropertyKey: item[$digitalProperty.propertyKey],
      workspaceId: item[$digitalProperty.workspace]._id,
      workspaceName: item[$digitalProperty.workspace].name,
    };
  }

  public static transformDigitalPropertiesIntoWorkspaces(
    digitalPropertiesResponse: IDigitalPropertiesListResponse,
    includeTenantName: boolean = false,
  ): IWorkspacePropsType[] {
    const result: IWorkspacePropsType[] = [];

    const byWorkspace: Record<string, IDigitalPropertyListItem[]> = groupBy(
      digitalPropertiesResponse.items,
      (dp: IDigitalPropertyListItem): string => dp[$digitalProperty.workspace]._id,
    );

    for (const properties of Object.values(byWorkspace)) {
      const workspace: IDigitalPropertyWorkspace = properties[0][$digitalProperty.workspace];
      const tenant: IDpWorkspaceTenant = workspace[$workspace.tenant];
      const tenantName: string = SharedCommonUtility.notNullish(tenant) && includeTenantName ? ` (${tenant[$tenant.name]})` : '';

      properties.sort((aValue: IDigitalPropertyListItem, bValue: IDigitalPropertyListItem): number =>
        aValue.name.localeCompare(bValue.name),
      );

      result.push({
        _id: workspace._id,
        workspaceName: `${workspace.name}${tenantName}`,
        digitalProperties: properties,
        hidden: true,
      });
    }

    result.sort((a: IWorkspacePropsType, b: IWorkspacePropsType): number => a.workspaceName.localeCompare(b.workspaceName));

    return result;
  }

  public currentSelectedProperty(): Observable<ICurrentSelectedProperty> {
    if (LinkedPropertyUtility.hasLinkedPropertyData(this.activatedRoute.snapshot.queryParams)) {
      return this.getCurrentPropertyFromParams();
    }

    return this.userService.currentDigitalProperty$.pipe(take(1), map(UserPropertyService.toCurrentSelectedProperty));
  }

  public currentSelectedPropertyItem(): Observable<IDigitalPropertyListItem> {
    return this.currentSelectedProperty().pipe(mergeMap(() => this.userService.currentDigitalProperty$.pipe(take(1))));
  }

  public switchUserToDigitalPropertyIfNeeded(workspaceId: string, digitalPropertyId: string): Observable<boolean> {
    return this.userService.currentDigitalProperty$.pipe(
      take(1),
      mergeMap((prop: IDigitalPropertyListItem | null) => {
        const currentlySwitching: Set<string> = this.currentlySwitchingProperties.getValue();
        if (currentlySwitching.has(digitalPropertyId)) {
          return of(false);
        }

        if (prop?.[$digitalProperty._id] === digitalPropertyId && prop?.[$digitalProperty.workspace]?._id === workspaceId) {
          return of(false);
        }

        this.currentlySwitchingProperties.next(currentlySwitching.add(digitalPropertyId));

        return this.userService.switchUserToDigitalProperty(workspaceId, digitalPropertyId).pipe(
          finalize((): void => {
            currentlySwitching.delete(digitalPropertyId);
            this.currentlySwitchingProperties.next(currentlySwitching);
          }),
          mergeMap(() => this.userService.currentDigitalProperty$),
          take(1),
          mergeMap((property: IDigitalPropertyListItem) => {
            if (SharedCommonUtility.notNullish(property)) {
              return from(
                this.router
                  .navigate([], {
                    queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(
                      property[$digitalProperty._id],
                      property[$digitalProperty.workspace]._id,
                    ),
                    queryParamsHandling: 'merge',
                  })
                  .then((): IDigitalPropertyListItem => property),
              );
            }

            // redirect to profile page with no query params (removing the invalid linkedpropertydata)
            return from(this.router.navigate([Api.settings, Api.profile], {}).then(() => null));
          }),
          tap((property: IDigitalPropertyListItem) => {
            if (SharedCommonUtility.isNullish(property)) {
              return;
            }
          }),
          map((property: IDigitalPropertyListItem) => SharedCommonUtility.notNullish(property)),
        );
      }),
    );
  }

  public currentSelectedWorkspace(): Observable<ICurrentSelectedWorkspace> {
    return this.currentSelectedProperty().pipe(
      map((prop: ICurrentSelectedProperty): ICurrentSelectedWorkspace | null => {
        if (prop === null) {
          return null;
        }

        const byWorkspace = (listItem: IDigitalPropertyListItem): boolean => {
          return listItem[$digitalProperty.workspace]._id === prop.workspaceId;
        };

        const userServerResponse: IUserServerResponse = this.userService.retrieveAuthenticatedUserProfile();

        return {
          workspaceId: prop.workspaceId,
          workspaceName: prop.workspaceName,
          workspaceSettings: userServerResponse.workspaces.find((w: IWorkspaceClient) => w._id === prop.workspaceId)?.settings,
          digitalProperties: userServerResponse[$user.digitalProperties].filter(byWorkspace),
        };
      }),
    );
  }

  public getAvailableDigitalProperties(typeIds?: string[]): Observable<IDigitalPropertiesListResponse> {
    return this.currentSelectedProperty().pipe(
      take(1),
      mergeMap((property: ICurrentSelectedProperty): Observable<IDigitalPropertiesListResponse> => {
        if (SharedCommonUtility.getTypeOf(property) !== 'object') {
          return of({ items: [], _total: 0 });
        }
        return this.userRestAPI.getAvailableDigitalProperties(typeIds);
      }),
    );
  }

  public redirectOnPropertyChange(redirectUrl: string[]): Subscription {
    return this.busMessageService
      .from(BusMessageChannels.userSwitchedToDigitalProperty)
      .pipe(skip(1), mergeMap(this.currentSelectedProperty.bind(this)))
      .subscribe(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): any =>
        this.router.navigate(redirectUrl, {
          replaceUrl: true,
          queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(digitalPropertyId, workspaceId),
        }),
      );
  }

  public getLinkedPropertyChanges$(): Observable<ILinkedPropertyData> {
    return this.activatedRoute.queryParams.pipe(
      filter(LinkedPropertyUtility.hasLinkedPropertyData),
      map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
      distinctUntilChanged(isEqual),
    );
  }

  public get digitalPropertySwitched$(): Observable<ICurrentSelectedProperty> {
    return this.busMessageService.from(BusMessageChannels.userSwitchedToDigitalProperty).pipe(
      skip(1),
      mergeMap((): Observable<ICurrentSelectedProperty> => this.currentSelectedProperty()),
    );
  }
}
