import { TestGuideCodeName } from './test-guide';
import { ruleLibraryCodeNames } from './rule-library';
import { standardNameAndLevel } from './scanning';
import { AuditStandards } from './audit-standard';

export enum EvaluationMediums {
  web = 'web',
  web_ea = 'web_ea',
  android = 'android',
  iOS = 'iOS',
  hardware = 'hardware',
  pv_web = 'pv_web',
}

export const EVALUATION_MEDIUMS: Readonly<EvaluationMediums[]> = Object.freeze(
  Object.values(EvaluationMediums) as EvaluationMediums[],
);

export const EVALUATION_MEDIUM_TO_TEST_GUIDE: Readonly<Record<EvaluationMediums, TestGuideCodeName>> = {
  [EvaluationMediums.web]: TestGuideCodeName.WEB_LA,
  [EvaluationMediums.web_ea]: TestGuideCodeName.WCAG22_EA,
  [EvaluationMediums.pv_web]: TestGuideCodeName.PV_WEB,
  [EvaluationMediums.android]: TestGuideCodeName.Android_LA,
  [EvaluationMediums.iOS]: TestGuideCodeName.IOS_LA,
  [EvaluationMediums.hardware]: TestGuideCodeName.Hardware_Section508,
};

export enum EvaluationStandards {
  WCAG_v20_A = standardNameAndLevel.WCAG_v20_A,
  WCAG_v20_AA = standardNameAndLevel.WCAG_v20_AA,
  WCAG_v20_AAA = standardNameAndLevel.WCAG_v20_AAA,
  WCAG_v21_A = standardNameAndLevel.WCAG_v21_A,
  WCAG_v21_AA = standardNameAndLevel.WCAG_v21_AA,
  WCAG_v21_AAA = standardNameAndLevel.WCAG_v21_AAA,
  WCAG_v22_A = standardNameAndLevel.WCAG_v22_A,
  WCAG_v22_AA = standardNameAndLevel.WCAG_v22_AA,
  WCAG_v22_AAA = standardNameAndLevel.WCAG_v22_AAA,
  section508 = AuditStandards.section508,
  en301549 = AuditStandards.en301549,
}

export const EVALUATION_STANDARDS: Readonly<EvaluationStandards[]> = Object.freeze(
  Object.values(EvaluationStandards) as EvaluationStandards[],
);

export const EVALUATION_MEDIUM_TO_RULE_LIBRARY_CODE: Readonly<Record<EvaluationMediums, ruleLibraryCodeNames>> = {
  [EvaluationMediums.web]: ruleLibraryCodeNames.web_la,
  [EvaluationMediums.web_ea]: ruleLibraryCodeNames.web_ea,
  [EvaluationMediums.android]: ruleLibraryCodeNames.android_la,
  [EvaluationMediums.iOS]: ruleLibraryCodeNames.ios_la,
  [EvaluationMediums.hardware]: ruleLibraryCodeNames.hardware_la,
  [EvaluationMediums.pv_web]: ruleLibraryCodeNames.pv_web,
};

export const RULE_LIBRARY_CODE_TO_EVALUATION_MEDIUM: Readonly<Partial<Record<ruleLibraryCodeNames, EvaluationMediums>>> =
  Object.fromEntries(
    Object.entries(EVALUATION_MEDIUM_TO_RULE_LIBRARY_CODE).map(([medium, code]: [EvaluationMediums, ruleLibraryCodeNames]) => [
      code,
      medium,
    ]),
  );

export const EVALUATION_STANDARD_TO_AUDIT_STANDARD: Readonly<Record<EvaluationStandards, AuditStandards>> = {
  [EvaluationStandards.WCAG_v20_A]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v20_AA]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v20_AAA]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v21_A]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v21_AA]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v21_AAA]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v22_A]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v22_AA]: AuditStandards.wcag,
  [EvaluationStandards.WCAG_v22_AAA]: AuditStandards.wcag,
  [EvaluationStandards.en301549]: AuditStandards.en301549,
  [EvaluationStandards.section508]: AuditStandards.section508,
};

export const WCAG_FAMILY_TO_SPECIFIC_STANDARDS: Readonly<Record<string, EvaluationStandards[]>> = {
  [AuditStandards.wcag]: [
    EvaluationStandards.WCAG_v20_A,
    EvaluationStandards.WCAG_v20_AA,
    EvaluationStandards.WCAG_v20_AAA,
    EvaluationStandards.WCAG_v21_A,
    EvaluationStandards.WCAG_v21_AA,
    EvaluationStandards.WCAG_v21_AAA,
    EvaluationStandards.WCAG_v22_A,
    EvaluationStandards.WCAG_v22_AA,
    EvaluationStandards.WCAG_v22_AAA,
  ],
  [EvaluationStandards.WCAG_v20_A]: [EvaluationStandards.WCAG_v20_A],
  [EvaluationStandards.WCAG_v20_AA]: [EvaluationStandards.WCAG_v20_A, EvaluationStandards.WCAG_v20_AA],
  [EvaluationStandards.WCAG_v20_AAA]: [
    EvaluationStandards.WCAG_v20_A,
    EvaluationStandards.WCAG_v20_AA,
    EvaluationStandards.WCAG_v20_AAA,
  ],
  [EvaluationStandards.WCAG_v21_A]: [EvaluationStandards.WCAG_v21_A],
  [EvaluationStandards.WCAG_v21_AA]: [EvaluationStandards.WCAG_v21_A, EvaluationStandards.WCAG_v21_AA],
  [EvaluationStandards.WCAG_v21_AAA]: [
    EvaluationStandards.WCAG_v21_A,
    EvaluationStandards.WCAG_v21_AA,
    EvaluationStandards.WCAG_v21_AAA,
  ],
  [EvaluationStandards.WCAG_v22_A]: [EvaluationStandards.WCAG_v22_A],
  [EvaluationStandards.WCAG_v22_AA]: [EvaluationStandards.WCAG_v22_A, EvaluationStandards.WCAG_v22_AA],
  [EvaluationStandards.WCAG_v22_AAA]: [
    EvaluationStandards.WCAG_v22_A,
    EvaluationStandards.WCAG_v22_AA,
    EvaluationStandards.WCAG_v22_AAA,
  ],
};

export const IDENTIFIER_TO_EVALUATION_STANDARDS: Record<string, EvaluationStandards> = Object.freeze({
  'WCAG_2.0_A': EvaluationStandards.WCAG_v20_A,
  'WCAG_2.0_AA': EvaluationStandards.WCAG_v20_AA,
  'WCAG_2.0_AAA': EvaluationStandards.WCAG_v20_AAA,
  'WCAG_2.1_A': EvaluationStandards.WCAG_v21_A,
  'WCAG_2.1_AA': EvaluationStandards.WCAG_v21_AA,
  'WCAG_2.1_AAA': EvaluationStandards.WCAG_v21_AAA,
  'WCAG_2.2_A': EvaluationStandards.WCAG_v22_A,
  'WCAG_2.2_AA': EvaluationStandards.WCAG_v22_AA,
  'WCAG_2.2_AAA': EvaluationStandards.WCAG_v22_AAA,
  'EN 301 549_v3.2.1': EvaluationStandards.en301549,
  'Section 508_v2018': EvaluationStandards.section508,
});
