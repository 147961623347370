export enum $scanSummaries {
  _id = '_id',
  scanId = 'scanId',
  toolId = 'toolId',
  documentId = 'documentId',
  scanAuditsId = 'scanAuditsId',
  url = 'url',
  score = 'score',
  algorithm = 'algorithm',
  metrics = 'metrics',
  triggeredMetrics = 'triggeredMetrics',
  triggeredMetric = 'triggeredMetric',
  summaries = 'summaries',
  total = 'total',
  scanPoint = 'scanPoint',
  document = 'document',
  title = 'title',
  resolvedFindingsSinceLastScan = 'resolvedFindingsSinceLastScan',
}

export enum $componentSummary {
  componentId = 'componentId',
  errorCount = 'errorCount',
  ignoredCount = 'ignoredCount',
}

export enum $componentPageSummary {
  scanId = 'scanId',
  documentId = 'documentId',
  scanAudit = 'scanAudit',
  url = 'url',
  score = 'score',
  metrics = 'metrics',
}

export enum $triggeredRuleSummary {
  ruleId = 'ruleId',
  ruleName = 'ruleName',
  errorCount = 'errorCount',
  passedCount = 'passedCount',
  ignoredCount = 'ignoredCount',
  applicableWeight = 'applicableWeight',
  weightedPassedRule = 'weightedPassedRule',
  components = 'components',
}

export enum $ruleSummary {
  errors = 'errors',
  passed = 'passed',
  ignored = 'ignored',
  triggered = 'triggered',
  applicableWeight = 'applicableWeight',
  weightedPassedRule = 'weightedPassedRule',
  ruleName = 'ruleName',
  components = 'components',
}

export enum ScoringAlgorithm {
  average = 'average',
  simpleRatio = 'simpleRatio',
  weightedAverage = 'weighted_average',
  adjustedWeightedAverage = 'adjusted_weighted_average',
}

export const SCORE_ADJUSTMENT_MULTIPLIER: number = 0.85;
