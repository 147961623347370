import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { createFocusTrap, FocusTrap, Options as FocusTrapOptions } from 'focus-trap';

import { IPaginationModel, IPaginationParams } from '../interfaces/pagination.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { $paginationParams } from '../constants/pagination-params';
import { $sortingOrder } from '../../../shared/constants/sort';
import { _getRandomSecureString } from '../../../shared/utils/random-secure-string.utility';

export class CommonUtility {
  private static focusTrap: FocusTrap;
  public static isHtmlDocument(el: any): boolean {
    return Boolean(el) && el.nodeType === Node.DOCUMENT_NODE;
  }

  public static createUniqueDOMId(preferredId?: string): string {
    if (typeof preferredId === 'string' && preferredId.trim().length > 0 && document.getElementById(preferredId) === null) {
      return preferredId;
    }
    let id: string;

    do {
      id = `uid_${SharedCommonUtility.getRandomInsecureString()}`;
    } while (document.getElementById(id));

    return id;
  }

  public static getKeyCode(event: KeyboardEvent): number {
    return event.type === 'keypress' ? event.charCode || event.keyCode || event.which : event.which || event.keyCode;
  }

  public static setFocusToElement(_id: string, element?: HTMLElement): void {
    const setFocus: any = (): void => {
      const htmlElement: HTMLElement = typeof _id === 'string' ? document.getElementById(_id) : element;

      if (CommonUtility.isHtmlElement(htmlElement) === false) {
        return;
      }

      htmlElement.focus();
    };

    window.setTimeout(setFocus);
  }

  public static getPageSize(): { height: number; width: number } {
    const body: HTMLElement = document.body;
    const root: HTMLElement = document.documentElement;

    const pageHeight: number = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      body.clientHeight,
      root.scrollHeight,
      root.offsetHeight,
      root.clientHeight,
    );
    const pageWidth: number = Math.max(
      body.scrollWidth,
      body.offsetWidth,
      body.clientWidth,
      root.clientWidth,
      root.scrollWidth,
      root.offsetWidth,
    );

    return {
      height: pageHeight,
      width: pageWidth,
    };
  }

  public static getPageScroll(): { scrollX: number; scrollY: number } {
    let scrOfX: number = 0;
    let scrOfY: number = 0;

    if (typeof window.pageYOffset === 'number') {
      scrOfY = window.pageYOffset;
      scrOfX = window.pageXOffset;
    } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
      scrOfY = document.body.scrollTop;
      scrOfX = document.body.scrollLeft;
    } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
      scrOfY = document.documentElement.scrollTop;
      scrOfX = document.documentElement.scrollLeft;
    }

    return { scrollX: scrOfX, scrollY: scrOfY };
  }

  public static calculateColumnsPagination(totalItems: number, currentPage: number = 1, pageSize: number = 10): IPaginationModel {
    const totalPages: number = Math.ceil(totalItems / pageSize);
    let _currentPage: number = currentPage;

    if (_currentPage < 1) {
      _currentPage = 1;
    } else if (_currentPage > totalPages) {
      _currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;

    const columns: number = 6;
    const middle: number = Math.ceil(columns / 2);

    if (totalPages <= columns) {
      startPage = 1;
      endPage = totalPages;
    } else if (_currentPage <= middle + 1) {
      startPage = 1;
      endPage = columns;
    } else if (_currentPage + middle - 1 >= totalPages) {
      startPage = totalPages - (columns - 1);
      endPage = totalPages;
    } else {
      startPage = _currentPage - middle;
      endPage = _currentPage + middle - 1;
    }

    const startIndex: number = (_currentPage - 1) * pageSize;
    const endIndex: number = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // Array of pages for ngFor
    const getNextPage: any = (i: number): number => {
      return startPage + i;
    };

    const pages: any = Array.from(Array(endPage + 1 - startPage).keys()).map(getNextPage);

    return {
      totalItems: totalItems,
      currentPage: _currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  public static isHostMethod(obj: any, method: string): boolean {
    const reMethod: RegExp = /^(function|object)$/;
    const reUnknown: RegExp = /^unknown$/;

    if (!obj) {
      return false;
    }

    const t: string = typeof obj[method];

    return reUnknown.test(t) || (reMethod.test(t) && obj) || false;
  }

  public static isHostObjectProperty(obj: Function | Document | Element | Record<string, unknown>, property: string): boolean {
    const reMethod: RegExp = /^(function|object)$/;
    const t: string = typeof obj[property];

    return Boolean(reMethod.test(t) && obj[property]);
  }

  public static isRealObjectProperty(obj: any, prop: string): boolean {
    return Boolean(typeof obj[prop] === 'object' && obj[prop]);
  }

  // Based on: http://javascript.nwbox.com/CSSSupport/
  public static isCSSselectorSupported(selector: string): boolean {
    let isSupported: boolean = false;

    if (selector.length === 0) {
      return isSupported;
    }

    const root: Element = document.documentElement;
    const head: HTMLHeadElement = document.head;

    const styleEl: HTMLStyleElement = document.createElement('style');

    styleEl.type = 'text/css';

    (head || root).insertBefore(styleEl, (head || root).firstChild);

    const sheet: CSSGroupingRule = styleEl.sheet || (styleEl as any).styleSheet;

    // sheet could be null or undefined
    if (Boolean(sheet) === false) {
      return isSupported;
    }

    const css2: any = (cssSelector: string): boolean => {
      try {
        sheet.insertRule(cssSelector + '{ }', 0);
        sheet.deleteRule(sheet.cssRules.length - 1);
      } catch (e) {
        return false;
      }
      return true;
    };

    const css3: any = (cssSelector: string): boolean => {
      sheet.cssText = cssSelector + ' { }';
      return sheet.cssText.length !== 0 && !/unknown/i.test(sheet.cssText) && sheet.cssText.indexOf(cssSelector) === 0;
    };

    if (
      CommonUtility.isHostObjectProperty(document, 'implementation') &&
      CommonUtility.isHostMethod(document.implementation, 'hasFeature')
    ) {
      isSupported = css2(selector);
    } else {
      isSupported = css3(selector);
    }

    return isSupported;
  }

  public static createFormData<T>(angularForm: T, stringArrayAsArray: boolean = false): FormData {
    const formData: FormData = new FormData();

    for (const key of Object.keys(angularForm)) {
      CommonUtility.appendFormData(formData, angularForm, key as keyof T, stringArrayAsArray);
    }

    return formData;
  }

  public static appendFormData<T>(form: FormData, object: T, keyInObject: keyof T, stringArrayAsArray: boolean): void {
    const value: any = object[keyInObject];
    let data: string;

    if (typeof value === 'string') {
      data = value;
    } else if (value instanceof Date) {
      data = value.toISOString();
    } else if (Array.isArray(value) && value.length > 0 && (value[0] instanceof File || value[0] instanceof Blob)) {
      const appendFile: (file: File, index: number) => void = (file: File, index: number): void => {
        form.append(`${String(keyInObject)}_${index}`, file, file.name);
      };
      value.forEach(appendFile);
      return;
    } else if (value instanceof File || value instanceof Blob) {
      form.append(String(keyInObject), value, (value as File).name);
      return;
    } else if (stringArrayAsArray && Array.isArray(value) && (typeof value[0] === 'string' || typeof value[0] === 'undefined')) {
      for (const valueElement of value) {
        form.append(String(keyInObject), valueElement);
      }
      return;
    } else {
      data = JSON.stringify(value);
    }

    form.append(String(keyInObject), data);
    return;
  }

  public static triggerClickEventOnElement(elementId: string): void {
    // Details: https://blog.mariusschulz.com/2016/05/31/programmatically-opening-a-file-dialog-with-javascript
    const eventProperties: MouseEventInit = {
      bubbles: false,
      cancelable: true,
      view: window,
    };

    const eventObject: MouseEvent = new MouseEvent('click', eventProperties);
    const inputTypeFile: HTMLElement = document.getElementById(elementId);

    inputTypeFile.dispatchEvent(eventObject);
  }

  public static scrollElementIntoView(
    element: string | HTMLElement,
    options: ScrollIntoViewOptions = { behavior: 'auto', block: 'start' },
  ): void {
    const el: HTMLElement = typeof element === 'string' ? document.getElementById(element) : element;

    if (CommonUtility.isHostMethod(el, 'scrollIntoView') === false) {
      return;
    }

    el.scrollIntoView(options);
  }

  public static scrollPageToTop(): void {
    const options: ScrollToOptions = {
      top: 0,
      left: 0,
      behavior: 'smooth',
    };

    window.scrollTo(options);
  }

  public static isFormActionElement(event: Event): boolean {
    const currentTarget: HTMLFormElement = event.currentTarget as HTMLFormElement;
    const eventTarget: HTMLElement = event.target || (event.srcElement as any);
    const targetName: string = eventTarget.nodeName.toLowerCase();

    let isFormActionElement: boolean = false;

    if (currentTarget.nodeName.toLowerCase() === eventTarget.nodeName.toLowerCase()) {
      isFormActionElement = true;
      return isFormActionElement;
    }

    if (currentTarget.nodeName.toLowerCase() !== 'form') {
      return isFormActionElement;
    }

    const formElements: HTMLFormControlsCollection = currentTarget.elements;
    const len: number = formElements.length;

    for (let i: number = 0; i < len; i += 1) {
      if (targetName === formElements[i].nodeName.toLowerCase()) {
        isFormActionElement = true;
        break;
      }
    }

    return isFormActionElement;
  }

  public static getRootElement(): HTMLElement {
    return document.documentElement || document.getElementsByTagName('html')[0];
  }

  public static requestIdleCallback(fn: Function): void {
    if (CommonUtility.isHostMethod(window, 'requestIdleCallback')) {
      (window as any).requestIdleCallback(fn);
      return;
    }

    window.setTimeout(fn, 50);
  }

  public static isEventSupported(event: string, htmlElement?: HTMLElement): boolean {
    const TAGNAMES: { [key: string]: string } = {
      select: 'input',
      change: 'input',
      submit: 'form',
      reset: 'form',
      error: 'img',
      load: 'img',
      abort: 'img',
    };

    let element: HTMLElement = htmlElement || document.createElement(TAGNAMES[event] || 'div');
    const eventName: string = 'on' + event;

    // When using `setAttribute`, IE skips "unload", WebKit skips "unload" and "resize", whereas `in` "catches" those
    let isSupported: boolean = eventName in document;

    if (isSupported === false) {
      if (CommonUtility.isHostMethod(element, 'setAttribute') === false) {
        element = document.createElement('div');
      }

      if (CommonUtility.isHostMethod(element, 'setAttribute') && CommonUtility.isHostMethod(element, 'removeAttribute')) {
        element.setAttribute(eventName, '');
        isSupported = typeof element[eventName] === 'function';

        if (typeof element[eventName] !== 'undefined') {
          element[eventName] = undefined;
        }

        element.removeAttribute(eventName);
      }
    }

    return isSupported;
  }

  public static getDocumentWindow(): Window | undefined {
    if (CommonUtility.isRealObjectProperty(document, 'parentWindow')) {
      return (document as any).parentWindow;
    }
    if (CommonUtility.isRealObjectProperty(document, 'defaultView') && document.defaultView === window) {
      return document.defaultView;
    }
    if (CommonUtility.isRealObjectProperty(document, '__parent__')) {
      return (document as any).__parent__;
    }
    return undefined;
  }

  public static getPreferredAvailableLanguageAndRegion(): string {
    const defaultLanguage: string = 'en-us';

    if (typeof window.navigator.language === 'string' && window.navigator.language.length > 0) {
      return window.navigator.language;
    }

    if (Array.isArray(window.navigator.languages) && window.navigator.languages.length > 0) {
      return window.navigator.languages[0];
    }

    return defaultLanguage;
  }

  // EAP-12286: change parameter typing back to Element | Node once we resolve typing collision
  public static isHtmlElement(el: any): boolean {
    return (
      (typeof Element !== 'undefined' && el instanceof Element) ||
      (typeof HTMLElement !== 'undefined' && el instanceof HTMLElement) ||
      (typeof HTMLDocument !== 'undefined' && el instanceof HTMLDocument) ||
      (typeof el === 'object' &&
        el !== null &&
        el.nodeType === Node.ELEMENT_NODE &&
        typeof (el as any).style === 'object' &&
        typeof el.ownerDocument === 'object')
    );
  }

  public static getStyle(element: Element, styleProp: string, pseudoElt?: string): string | null {
    const isHtmlElement: boolean = CommonUtility.isHtmlElement(element);

    if (isHtmlElement === false) {
      return null;
    }

    if (CommonUtility.isHostMethod(window, 'getComputedStyle')) {
      return window.getComputedStyle(element, pseudoElt || null).getPropertyValue(styleProp);
    }

    if (CommonUtility.isRealObjectProperty(document, 'defaultView')) {
      return document.defaultView.getComputedStyle(element, pseudoElt || null).getPropertyValue(styleProp);
    }

    return null;
  }

  public static getScript(
    url: string,
    onLoaded: (this: GlobalEventHandlers, ev: Event) => any,
    onFailed?: OnErrorEventHandlerNonNull,
    id?: string,
    defer?: boolean,
  ): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    const head: HTMLHeadElement = document.head;

    if (typeof url !== 'string') {
      throw new Error('[CommonUtility.getScript] missing url parameter');
    }

    script.src = url;
    script.type = 'text/javascript';
    script.async = true;

    if (typeof defer === 'boolean') {
      script.defer = defer;
    }

    if (id) {
      script.id = id;
    }

    if (onLoaded) {
      script.onload = onLoaded;
    }

    if (onFailed) {
      script.onerror = onFailed;
    }

    head.insertBefore(script, head.firstChild);

    return script;
  }

  public static isValidUrl = (str: string): boolean | undefined => {
    try {
      const url: URL = new URL(str);
      // Note: if statement here is to avoid tslint unused-variable issue
      if (url) {
        return true;
      }
    } catch (_) {
      return false;
    }
    return undefined;
  };

  /**
   * Tests if provided string is valid as a value to a Jira URL field.
   * (Jira uses a different implementation of the RFC 2396 url schema)
   */
  public static isValidJiraFieldUrl(str: string): boolean {
    const validator: RegExp = /^[A-Za-z][A-Za-z0-9]+:\/\/[^ \^'"<>\[\];\{\}|\%\\]*[^ \^'"<>\[\];\{\}|\%\\\@]$/;
    return validator.test(str);
  }

  public static isInputTypeSupported(type: string): boolean {
    const input: HTMLInputElement = document.createElement('input');
    input.setAttribute('type', type);

    const invalidValue: string = 'not-a-date';
    input.setAttribute('value', invalidValue);

    return input.value !== invalidValue;
  }

  public static navigateToExternalUrl(url: string): void {
    window.location.href = url;
  }

  public static convertArrayBufferHTTPErrorToObject(response: HttpErrorResponse): HttpErrorResponse {
    return {
      ...response,
      error: JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(response.error))),
    };
  }

  public static extractHTTPErrorName(response: HttpErrorResponse): string {
    if (typeof response?.error?.app?.name === 'string') {
      return response.error.app.name;
    } else if (
      response?.error instanceof ArrayBuffer ||
      response?.error instanceof Uint8Array ||
      response?.error?.[Symbol.toStringTag] === 'Uint8Array'
    ) {
      // if we are downloading raw data, the data comes as an ArrayBuffer
      // which unfortunately includes the error as well
      return CommonUtility.extractHTTPErrorName(CommonUtility.convertArrayBufferHTTPErrorToObject(response));
    }
    return null;
  }

  public static isValidPaginationQueryParams(queryParams: Params): boolean {
    return (
      SharedCommonUtility.isPositiveInteger(queryParams[$paginationParams.pageNumber]) &&
      SharedCommonUtility.isPositiveInteger(queryParams[$paginationParams.pageSize])
    );
  }

  public static getPageNumberFromQueryParams(queryParams: Params, defaultPageNumber: number = 1): number {
    return SharedCommonUtility.isPositiveInteger(queryParams[$paginationParams.pageNumber])
      ? Number(queryParams[$paginationParams.pageNumber])
      : defaultPageNumber;
  }

  public static isValidSortingQueryParams(queryParams: Params, columns: string[]): boolean {
    return (
      columns.includes(queryParams[$paginationParams.sortBy]) &&
      Object.keys($sortingOrder).includes(queryParams[$paginationParams.direction])
    );
  }

  /**
   * Returns a securely generated string of alphanumerics, case-sensitive.
   * Do not use this method to generate keys. This method can theoretically be used in insecure contexts (HTTP)
   * so a MITM attack is possible if you use it to generate keys. We shouldn't be generating keys on the frontend anyways.
   * If you must generate keys on the frontend, use SubtleCrypto.generateKeys
   *
   * @param length
   */
  public static getRandomSecureString(length: number = 16): string {
    return _getRandomSecureString(window.crypto.getRandomValues.bind(window.crypto), length);
  }

  public static pickSortingParams<T extends IPaginationParams>(
    paginationParams: T,
    defaultSortingParams: Pick<T, $paginationParams.sortBy | $paginationParams.direction>,
  ): Pick<T, $paginationParams.sortBy | $paginationParams.direction> {
    const sortBy: string = paginationParams[$paginationParams.sortBy];
    const direction: $sortingOrder = paginationParams[$paginationParams.direction];

    if (
      SharedCommonUtility.isNullishOrEmpty(sortBy) ||
      SharedCommonUtility.isNullish(direction) ||
      direction === $sortingOrder.all
    ) {
      return defaultSortingParams;
    }

    return {
      [$paginationParams.sortBy]: sortBy,
      [$paginationParams.direction]: direction,
    };
  }

  /**
   * Tries to activate a focus trap on a provided element.
   * Sets global CommonUtility.focusTrap when provided a trappable element
   * Otherwise, sets global CommonUtility.focusTrap to undefined
   *
   * WARNING: when a focus trap element is deleted or hidden, an error is raised.
   * Either call CommonUtility.focusTrap?.deactivate()
   * or add a fallbackFocus option
   *
   * @param element on which to activate a focus trap
   * @param options for focus trap
   */
  public static activateFocusTrap(element: HTMLElement, options: FocusTrapOptions = { allowOutsideClick: true }): undefined {
    CommonUtility.deactivateFocusTrap();

    if (SharedCommonUtility.isNullish(element)) {
      return undefined;
    }

    try {
      const focusTrap: FocusTrap = createFocusTrap(element, options);
      focusTrap.activate();
      CommonUtility.focusTrap = focusTrap;
    } catch (err) {
      console.warn(err);
    }

    return undefined;
  }

  /**
   * Tries to deactivate the focus trap
   *
   * WARNING: when a focus trap element is deleted or hidden, an error is raised.
   * Either call CommonUtility.deactivateFocusTrap()
   * or add a fallbackFocus option to the activateFocusTrap function
   */
  public static deactivateFocusTrap(): void {
    CommonUtility.focusTrap?.deactivate();
  }

  public static isFocusTrapActive(): boolean {
    return CommonUtility.focusTrap?.active;
  }

  /**
   * Returns all focusable elements inside the provided container node
   *
   * @param container in which to look for focusable elements
   */
  public static getFocusableElements(container: Element): Element[] {
    if (SharedCommonUtility.isNullish(container)) {
      throw new Error('[CommonUtility.getFocusableElements] missing container parameter');
    }

    return Array.from(
      container.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'),
    ).filter((element: Element): boolean => element.getAttribute('tabindex') !== '-1');
  }
}
