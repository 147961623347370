<ds-modal class="modal-container" [title]="'Chat'" [colorVariant]="DsModalColorVariant.Default">
  <div class="chat-content" [class.has-messages]="messages.length > 0">
    <div class="messages-container" tabindex="0">
      <div *ngFor="let message of messages; let index = index" class="d-flex flex-column gap-3 mb-2">
        <div class="user mb-2" id="user-query-{{ message._id }}">
          <div class="label mb-2">{{ userName }}</div>
          <div class="user-message">{{ message.message }}</div>
        </div>
        <div class="ai" id="ai-response-{{ message._id }}" [ngClass]="message.status" [ngSwitch]="message.status">
          <ng-container *ngSwitchCase="IChatMessageStatus.error">
            <div class="d-flex gap-3">
              <div><ds-icon [icon]="Icons.CircleExclamation"></ds-icon></div>
              <div>
                <div>{{ 'ai_response_not_available' | translate }}</div>
                <button class="ps-0 btn btn-link" (click)="retry(message.message, index)">
                  {{ 'retry_label' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="label mb-2">{{ 'level_access' | translate }}</div>
            <app-ai-chat-markdown-view [value]="message.response"></app-ai-chat-markdown-view>
            <div class="ai-rating-buttons d-flex justify-content-end mt-3">
              <ds-checkboxes-feedback
                *featureFlag="'!' + FeatureFlagCollection.aiChatResponseScale"
                [value]="message.rating"
                (valueChange)="onRatingChange(message, $event)"
                [title]="ratingTitle"
                [options]="ratingOptions"
              ></ds-checkboxes-feedback>
              <ds-star-rating
                *featureFlag="FeatureFlagCollection.aiChatResponseScale"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="message.rating"
                (ngModelChange)="onRatingChange(message, $event)"
              >
              </ds-star-rating>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="ai-warning" *ngIf="messages.length === 0">
      <div class="ai-warning-icon">
        <ds-icon [icon]="Icons.Sparkles" [scale]="IconScale.FA_3X" [iconStyle]="IconStyles.Light"></ds-icon>
      </div>
      <div>
        <p>{{ 'ai_chat_description' | translate }}</p>
      </div>
    </div>
    <form class="d-flex flex-column mt-auto pt-2" [formGroup]="form" (ngSubmit)="sendMessage()">
      <app-common-textarea
        class="mb-2 px-1"
        (keydown.control.enter)="sendMessage()"
        (keydown.meta.enter)="sendMessage()"
        [form]="form"
        [formValidationRequest$]="formValidationRequest$"
        [context]="textAreaContext"
      >
      </app-common-textarea>
      <div class="px-1 pb-1">
        <button [disabled]="!sendMessageSubscription.closed" ds-button [variant]="DsButtonVariants.primary">
          {{ 'action_send' | translate }}
        </button>
      </div>
    </form>
  </div>
</ds-modal>
