<div class="d-flex flex-row flex-wrap align-items-center justify-content-center">
  <div class="d-flex flex-column align-items-center">
    <span class="visually-hidden">
      {{ 'scan_score_chart_aria_label' | translate: [score] }}
    </span>
    <div #scoreChartTarget id="scoreChart" [ngClass]="scoreClass" aria-hidden="true" ></div>
    <span >{{ computedDate }}</span>
  </div>
  @if (!hideLegend) {
    <div class="d-flex align-items-center flex-column" >
      <div class="d-flex legend-container flex-column mb-3">
        <div class="d-flex">
          <strong>{{ 'legend' | translate }}</strong>
        </div>
        <ul class="list-unstyled d-flex flex-column m-0 mt-1" >
          <li class="d-flex align-items-center me-1 me-md-3">
            <svg class="square bg-low"><rect height="100%" width="100%" /></svg>
            <strong class="margin-right-xs"> {{ 'label_score_weak' | translate }}: </strong>
            <span>
              {{ 'n_dash_m' | translate: [scoreRanges[scoreRangeLabels.low][0], scoreRanges[scoreRangeLabels.low][1]] }}
            </span>
          </li>
          <li class="d-flex align-items-center me-1 me-md-3">
            <svg class="square bg-mid"><rect height="100%" width="100%" /></svg>
            <strong class="margin-right-xs"> {{ 'label_score_average' | translate }}: </strong>
            <span>
              {{ 'n_dash_m' | translate: [scoreRanges[scoreRangeLabels.mid][0], scoreRanges[scoreRangeLabels.mid][1]] }}
            </span>
          </li>
          <li class="d-flex align-items-center me-1 me-md-3">
            <svg class="square bg-high"><rect height="100%" width="100%" /></svg>
            <strong class="margin-right-xs"> {{ 'label_score_strong' | translate }}: </strong>
            <span>
              {{ 'n_dash_m' | translate: [scoreRanges[scoreRangeLabels.high][0], scoreRanges[scoreRangeLabels.high][1]] }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  }
  <svg class="d-none">
    <defs #svgPatterns>
      <pattern id="pattern-health-mid" patternUnits="userSpaceOnUse" width="4px" height="4px">
        <path d="M-1,1 l2,-2  M0,4 l4,-4 M3,5 l2,-2" [attr.stroke]="colors[scoreRangeLabels.mid]" stroke-width="2" />
      </pattern>
      <pattern id="pattern-health-low" viewBox="0,0,4,4" patternUnits="userSpaceOnUse" height="4px" width="4px">
        <circle cx="2" cy="2" r="2" [attr.fill]="colors[scoreRangeLabels.low]"></circle>
      </pattern>
    </defs>
  </svg>
</div>
